import type { DialogProps } from '@mui/material'
import {
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { NetworkSelectEx, type NetworkSelectExProps } from '@xyo-network/react-network'
import type { WalletAccountSelectBarProps } from '@xyo-network/react-wallet'
import { WalletAccountSelectBar } from '@xyo-network/react-wallet'
import type { Dispatch, SetStateAction } from 'react'
import React from 'react'

export interface MobileDialogProps extends DialogProps {
  addressNames?: WalletAccountSelectBarProps['addressNames']
  maxAccounts?: number
  networkSelectProps?: NetworkSelectExProps
  setOpen?: Dispatch<SetStateAction<boolean>>
}

export const MobileDialog: React.FC<MobileDialogProps> = ({
  addressNames, maxAccounts, networkSelectProps, setOpen, ...props
}) => {
  return (
    <Dialog fullWidth {...props}>
      <DialogTitle>Site Configuration</DialogTitle>
      <DialogContent sx={{
        display: 'flex', flexDirection: 'column', gap: 2,
      }}
      >
        <FormControl>
          <FormHelperText>Select Node</FormHelperText>
          <NetworkSelectEx fullWidth {...networkSelectProps} />
        </FormControl>
        <FormControl>
          <FormHelperText>Select Signing Account</FormHelperText>
          <WalletAccountSelectBar addressNames={addressNames} icons maxAccounts={maxAccounts} showFavorite size="small" />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <ButtonEx
          variant="outlined"
          onClick={() => {
            setOpen?.(false)
          }}
        >
          Close
        </ButtonEx>
      </DialogActions>
    </Dialog>
  )
}
