import type { Hash } from '@xylabs/hex'
import { usePromise } from '@xylabs/react-promise'
import { Huri } from '@xyo-network/payload'
import { useNetwork } from '@xyo-network/react-network'
import { useMemo } from 'react'

export const useHuri = (hash?: Hash, refresh?: number) => {
  const { network } = useNetwork()
  const rootUri = useMemo(() => {
    switch (network?.name) {
      case 'Main': { return 'https://api.archivist.xyo.network'
      }
      case 'Kerplunk': { return 'https://beta.api.archivist.xyo.network'
      }
      case 'Local': { return 'http://localhost:8080'
      }
      default: {
        return
      }
    }
  }, [network])
  const [payload] = usePromise(async () => (hash && rootUri) ? await new Huri(`${rootUri}/${hash}`).fetch() : undefined, [hash, rootUri, refresh])
  return payload
}
