import type { HTMLAttributes, ReactNode } from 'react'
import React, {
  Children, createContext, forwardRef, useContext, useEffect, useMemo, useRef, useState,
} from 'react'
import type { ListChildComponentProps } from 'react-window'
import { VariableSizeList } from 'react-window'

const ItemSize = 48

const getChildSize = () => {
  return ItemSize
}

const useResetCache = (data: number) => {
  const ref = useRef<VariableSizeList>(null)

  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Selects the current row in the data set based off index and applies the necessary styles for react-window
const RenderRow: React.FC<ListChildComponentProps> = ({
  data, index, style,
}) => {
  const dataSet = data[index]
  return <div style={style}>{dataSet}</div>
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

OuterElementType.displayName = 'OuterElementType'

// Adapter for react-window
export const AddressesListbox = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(({ children, ...props }, ref) => {
  const [itemData, setItemData] = useState<ReactNode[]>([])

  useMemo(() => {
    if (children) {
      // eslint-disable-next-line @eslint-react/no-children-to-array
      setItemData([...Children.toArray(children)])
    }
  }, [children])

  const itemCount = itemData?.length

  const containerHeight = useMemo(() => {
    // handle short lists
    if (itemCount > 8) {
      return 8 * ItemSize
    }
    // add up the height of all items for the container height
    return itemData.map(getChildSize).reduce((acc, childSize) => acc + childSize, 0)
  }, [itemCount, itemData])

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={props}>
        <VariableSizeList
          itemData={itemData}
          height={containerHeight}
          innerElementType="ul"
          outerElementType={OuterElementType}
          width="100%"
          ref={gridRef}
          itemSize={() => ItemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {RenderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

AddressesListbox.displayName = 'AddressesListbox'
