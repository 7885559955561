import type { ContextExProviderProps } from '@xylabs/react-shared'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'

import type { BreadcrumbData } from '../../Breadcrumb.ts'
import { BreadcrumbsContext } from './Context.ts'
import { useBreadcrumbs } from './use.ts'

export type BreadcrumbProviderProps = ContextExProviderProps & BreadcrumbData

export const BreadcrumbProvider: React.FC<PropsWithChildren<BreadcrumbProviderProps>> = ({
  required, name, to, children,
}) => {
  const { breadcrumbs = [], root = '/' } = useBreadcrumbs()

  const resolvedRoot = to ? `${root}${to}/` : root

  const value = useMemo(() => ({
    breadcrumbs: [...breadcrumbs, { name, to: resolvedRoot }], provided: true, root: resolvedRoot,
  }), [breadcrumbs, name, resolvedRoot])

  return (
    <BreadcrumbsContext.Provider value={value}>
      {breadcrumbs.length > 0
        ? children
        : required
          ? null
          : children}
    </BreadcrumbsContext.Provider>
  )
}
