import type { SelectExProps } from '@xylabs/react-select'
import { useSearchParams } from 'react-router-dom'

export type ArchivistSelectProps = SelectExProps<string>

export const useSelectedArchivist = (defaultArchivist = 'Archivist', query = 'archivist'): [string, (select: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()
  return [
    searchParams.get(query) ?? defaultArchivist,
    (selected: string | undefined) => {
      if (selected === undefined) {
        searchParams.delete(query)
      } else {
        searchParams.set(query, selected)
      }
      setSearchParams(searchParams)
    },
  ]
}
