import { useAppSettings } from '@xylabs/react-app-settings'
import { UserEventsProvider, XyoUserEventHandler } from '@xylabs/react-pixel'
import { CollapsibleProvider } from '@xyo-network/react-shared'
import type { PropsWithChildren } from 'react'
import React from 'react'

export const UIProviders: React.FC<PropsWithChildren> = ({ children }) => {
  const { navigationCollapsed } = useAppSettings()
  return (
    <UserEventsProvider userEvents={XyoUserEventHandler.get()}>
      <CollapsibleProvider defaultCollapse={navigationCollapsed}>{children}</CollapsibleProvider>
    </UserEventsProvider>
  )
}
