import type { Theme } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import type { AddressPayload } from '@xyo-network/module-model'
import type { PageCardProps } from '@xyo-network/react-card'
import { PageCard } from '@xyo-network/react-card'
import React, { forwardRef, useMemo } from 'react'

import type { AddressWithFavorite } from '../../types/index.ts'
import type { SharableAddressAutoCompleteProps } from '../Address/index.ts'
import { AddressAutoComplete } from '../Address/index.ts'

export interface PageCardWithAddressesProps extends PageCardProps {
  addresses?: AddressWithFavorite[]
  autoCompleteProps?: SharableAddressAutoCompleteProps
  onAddressChange?: (value: AddressPayload | null) => void
  selectedAddress?: AddressPayload | null
}

export const PageCardWithAddresses = forwardRef<HTMLDivElement, PageCardWithAddressesProps>(
  ({
    addresses, autoCompleteProps, children, onAddressChange, selectedAddress, ...props
  }, ref) => {
    const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const AddressSelector = useMemo(
      () => (
        <AddressAutoComplete
          style={{ minWidth: 'calc(100vw / 3)' }}
          addresses={addresses}
          onChange={(_event, value) => onAddressChange?.(value)}
          value={selectedAddress ?? null}
          {...autoCompleteProps}
        />
      ),
      [addresses, autoCompleteProps, onAddressChange, selectedAddress],
    )

    return (
      <PageCard elevation={0} action={isMedium ? undefined : AddressSelector} ref={ref} sx={{ gap: isMedium ? 2 : 1 }} {...props}>
        {isMedium ? AddressSelector : null}
        {children}
      </PageCard>
    )
  },
)

PageCardWithAddresses.displayName = 'PageCardWithAddresses'
