import type { PayloadRenderPlugin } from '@xyo-network/react-payload'
import {
  CoinGeckoPricesRenderPlugin,
  CryptoAssetRenderPlugin,
  CryptoPricesRenderPlugin,
  DefaultRenderPlugin,
  EthereumGasPriceBlocknativePlugin,
  EthereumGasPriceEtherchainV2Plugin,
  EthereumGasPriceEtherscanPlugin,
  EthereumGasPriceEthersPlugin,
  EthereumGasPriceEthgasstationPlugin,
  EthereumGasPricePlugin,
  PointMapRenderPlugin,
  PointsMapRenderPlugin,
  QuadkeyHeatMapRenderPlugin,
  UniswapPairsRenderPlugin,
} from '@xyo-network/react-plugins'

import { PayloadPointerRenderPlugin } from './PayloadPointer/index.ts'

export * from './PayloadPointer/index.ts'

export type WithConfig<T> = T & {
  fillContainer?: boolean
  slug: string
}

export const DefaultPlugins: WithConfig<PayloadRenderPlugin>[] = [
  { ...EthereumGasPriceEthgasstationPlugin, slug: 'ethereum-gas-price-ethgasstation-render-plugin' },
  { ...EthereumGasPriceBlocknativePlugin, slug: 'ethereum-gas-price-blocknative-render-plugin' },
  { ...EthereumGasPriceEtherchainV2Plugin, slug: 'ethereum-gas-price-etherchain-v2-render-plugin' },
  { ...EthereumGasPriceEtherscanPlugin, slug: 'ethereum-gas-price-etherscan-render-plugin' },
  { ...EthereumGasPriceEthersPlugin, slug: 'ethereum-gas-price-ethers-render-plugin' },
  { ...EthereumGasPricePlugin, slug: 'ethereum-gas-price-render-plugin' },
  { ...CryptoAssetRenderPlugin, slug: 'crypto-asset-render-plugin' },
  { ...CoinGeckoPricesRenderPlugin, slug: 'coingecko-prices-render-plugin' },
  { ...QuadkeyHeatMapRenderPlugin, slug: 'heatmap-render-plugin' },
  {
    ...PointMapRenderPlugin, fillContainer: true, slug: 'point-map-render-plugin',
  },
  {
    ...PointsMapRenderPlugin, fillContainer: true, slug: 'points-map-render-plugin',
  },
  { ...CryptoPricesRenderPlugin, slug: 'crypto-prices-render-plugin' },
  { ...PayloadPointerRenderPlugin, slug: 'payload-pointer-render-plugin' },
  { ...UniswapPairsRenderPlugin, slug: 'uniswap-pairs-render-plugin' },
  { ...DefaultRenderPlugin, slug: 'details-render-plugin' },
]
