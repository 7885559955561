import { Warning as WarningIcon } from '@mui/icons-material'
import type { CardProps } from '@mui/material'
import {
  Alert, AlertTitle, Card, CardHeader, CircularProgress, Typography,
} from '@mui/material'
import { useAsyncEffect } from '@xylabs/react-async-effect'
import { ErrorRender } from '@xylabs/react-common'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { CardContentEx } from '@xyo-network/react-card'
import { SchemaCache } from '@xyo-network/schema-cache'
import type { SchemaPayload } from '@xyo-network/schema-payload-plugin'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { SchemaLoadedJsonViewer } from '../SchemaJsonViewer.tsx'

export const SchemaJsonCard: React.FC<CardProps> = ({ ...props }) => {
  const { schema } = useParams()

  const [payload, setPayload] = useState<SchemaPayload | null>()
  const [error, setError] = useState<Error>()

  useAsyncEffect(
    async (mounted) => {
      if (schema) {
        try {
          const entry = await SchemaCache.instance.get(schema)
          if (mounted()) {
            setPayload(entry === null ? null : entry?.payload)
          }
          setError(undefined)
        } catch (e) {
          setError(e as Error)
          setPayload(null)
        }
      }
    },
    [schema],
  )

  return (
    <Card {...props}>
      <CardHeader title="JSON Viewer" />
      <CardContentEx variant="scrollable" style={{ height: '100%', justifyContent: 'start' }}>
        <ErrorRender error={error}>
          {payload === undefined
            ? (
                <FlexGrowCol minHeight="100%">
                  <CircularProgress />
                </FlexGrowCol>
              )
            : payload == null
              ? (
                  <Alert icon={<WarningIcon fontSize="inherit" />} severity="warning">
                    <AlertTitle>No Official Definition Located</AlertTitle>
                    <Typography>
                      Uh Oh! We searched
                      {' '}
                      <span style={{ fontFamily: 'monospace' }}>{schema?.split('.').slice(0, 2).reverse().join('.')}</span>
                      {' '}
                      for an
                      official definition payload for this schema:
                      {' '}
                      <span style={{ fontFamily: 'monospace' }}>{schema}</span>
                    </Typography>
                  </Alert>
                )
              : <SchemaLoadedJsonViewer value={payload} />}
        </ErrorRender>
      </CardContentEx>
    </Card>
  )
}
