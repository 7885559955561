import { AppSettingsProvider } from '@xylabs/react-app-settings'
import type { PropsWithChildren } from 'react'
import React from 'react'

export const SettingsProviders: React.FC<PropsWithChildren> = ({ children }) => {
  const mapboxToken = import.meta.env.VITE_MAPBOX_TOKEN
  if (!mapboxToken) {
    console.warn('Please provide a mapbox access token')
  }
  return (
    <AppSettingsProvider value={{}}>
      {children}
      {/* <MapboxAccessTokenProvider defaultAccessToken={mapboxToken}>{children}</MapboxAccessTokenProvider>
      Note: Removed to prevent mapbox to be added to main bundle */}
    </AppSettingsProvider>
  )
}
