import type { PropsWithChildren } from 'react'
import React from 'react'

import { FavoriteAddressesProvider } from './contexts/index.ts'
import { FavoriteArchivist } from './FavoriteArchivist.tsx'

export const ExplorerFavorites: React.FC<PropsWithChildren> = ({ children }) => (
  <FavoriteAddressesProvider>
    <FavoriteArchivist>{children}</FavoriteArchivist>
  </FavoriteAddressesProvider>
)
