import type { Payload } from '@xyo-network/protocol'
import type { PayloadRenderPlugin } from '@xyo-network/react-payload'
import { createPayloadRenderPlugin } from '@xyo-network/react-payload'

import { PayloadPointerRenderer } from './components/index.ts'

export const PayloadPointerRenderPlugin: PayloadRenderPlugin = {
  ...createPayloadRenderPlugin({
    canRender: (payload?: Payload) => {
      return payload?.schema === 'network.xyo.payload.pointer'
    },
    components: { box: { details: PayloadPointerRenderer } },
    name: 'Payload Pointer',
  }),
}
