import type { TableRowProps } from '@mui/material'
import {
  ButtonGroup, TableCell, TableRow,
} from '@mui/material'
import { useAppSettings } from '@xylabs/react-app-settings'
import { ButtonEx } from '@xylabs/react-button'
import React, { useState } from 'react'

export const DeveloperModeTableRow: React.FC<TableRowProps> = (props) => {
  const { developerMode, enableDeveloperMode } = useAppSettings()
  const [clickString, setClickString] = useState('')

  const checkString = (clickString: string) => {
    setClickString(clickString)
    if (clickString === '123212321') {
      enableDeveloperMode?.(!developerMode)
      setClickString('')
    }
  }

  const onClick1 = () => {
    checkString(clickString + '1')
  }

  const onClick2 = () => {
    checkString(clickString + '2')
  }

  const onClick3 = () => {
    checkString(clickString + '3')
  }

  return (
    <TableRow {...props}>
      <TableCell>Developer Mode</TableCell>
      <TableCell align="center">{developerMode ? 'Enabled' : 'Disabled'}</TableCell>
      <TableCell align="center">
        <ButtonGroup fullWidth>
          <ButtonEx variant="contained" size="small" onClick={onClick1}>
            1
          </ButtonEx>
          <ButtonEx variant="contained" size="small" onClick={onClick2}>
            2
          </ButtonEx>
          <ButtonEx variant="contained" size="small" onClick={onClick3}>
            3
          </ButtonEx>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  )
}
