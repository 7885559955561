import type { Payload } from '@xyo-network/payload'
import { useEvent } from '@xyo-network/react-event'
import type { PaginationNouns } from '@xyo-network/react-payload-table'
import type { PropsWithChildren } from 'react'
import React, {
  useCallback, useMemo, useState,
} from 'react'

import { PayloadListContext } from './Context.tsx'

type PayloadListProviderProps = PropsWithChildren

export const PayloadListProvider: React.FC<PayloadListProviderProps> = ({ children }) => {
  const [totalPayloads, setTotalPayloads] = useState<Payload[] | undefined>([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState<string>()
  const [scrollToTop, setScrollTop] = useState(0)

  const [scrollRef] = useEvent<HTMLTableElement, PaginationNouns>((noun) => {
    if (noun === 'nextPage' && scrollRef.current) {
      setScrollTop(previous => previous + 1)
      // bring back once we have pagination
      // setOffset((previous) => {
      //   const lastItem = totalPayloads?.at(-1)
      //   if (lastItem) {
      //     return PayloadWrapper.wrap(lastItem).hash
      //   }
      //   return previous
      // })
    }
  })

  const resetList = useCallback(() => {
    setTotalPayloads([])
    setCount(0)
    setOffset(undefined)
    setLoading(true)
  }, [])

  useMemo(() => {
    if (offset) {
      setLoading(true)
    }
  }, [offset])

  const updateTotalPayloads = (additionalPayloads?: Payload[]) => {
    if (additionalPayloads && additionalPayloads.length > 0) {
      setTotalPayloads((previous) => {
        if (previous) {
          previous?.push(...additionalPayloads)
          setCount(previous?.length)
        }
        return previous
      })
    }
    return true
  }

  const value = useMemo(() => ({
    count,
    loading,
    offset,
    provided: true,
    resetList,
    scrollRef,
    scrollToTop,
    setLoading,
    setOffset,
    totalPayloads,
    updateTotalPayloads,
  }), [count,
    loading,
    offset,
    resetList,
    scrollRef,
    scrollToTop,
    setLoading,
    setOffset,
    totalPayloads,
    updateTotalPayloads])

  return (
    <PayloadListContext.Provider
      value={value}
    >
      {children}
    </PayloadListContext.Provider>
  )
}
