import type { TableProps } from '@mui/material'
import {
  CardContent, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material'
import { useAppSettings } from '@xylabs/react-app-settings'
import { PageCard } from '@xyo-network/react-card'
import {
  MaxAccountsTableRow, SeedPhraseTableRow, useWalletContext,
} from '@xyo-network/react-wallet'
import { WebAppPage } from '@xyo-network/react-webapp'
import React from 'react'

import { DynamicBreadcrumbs } from '../../../shared/index.ts'
import { DeveloperModeTableRow } from './DeveloperModeTableRow.tsx'

export const SettingsTable: React.FC<TableProps> = (props) => {
  const {
    changeMaxAccounts, changeSeedPhrase, maxAccounts, seedPhrase,
  } = useAppSettings()
  const { activeAccountIndex } = useWalletContext()
  return (
    <Table {...props}>
      <TableHead>
        <TableRow>
          <TableCell width="100%">Property</TableCell>
          <TableCell align="center">Setting</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <DeveloperModeTableRow />
        <SeedPhraseTableRow changeSeedPhrase={changeSeedPhrase} seedPhrase={seedPhrase} />
        <MaxAccountsTableRow activeAccountIndex={activeAccountIndex} changeMaxAccounts={changeMaxAccounts} maxAccounts={maxAccounts} />
      </TableBody>
    </Table>
  )
}

export const SettingsPage: React.FC = () => {
  return (
    <WebAppPage title="Settings" breadcrumbs={<DynamicBreadcrumbs name="Settings" to="settings" />}>
      <PageCard title="Settings">
        <CardContent>
          <SettingsTable />
        </CardContent>
      </PageCard>
    </WebAppPage>
  )
}
