import type { ChipProps } from '@mui/material'
import { Chip, Fade } from '@mui/material'
import React from 'react'

import { useSchemaStatsFilter } from '../../contexts/index.ts'

export const SchemaFilterChip: React.FC<ChipProps> = (props) => {
  const { schemaFilter, schemaFilterBgColor } = useSchemaStatsFilter()

  return (
    <Fade in={!!schemaFilter}>
      <Chip label={schemaFilter} sx={{ backgroundColor: schemaFilterBgColor }} {...props} />
    </Fade>
  )
}
