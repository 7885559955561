import { usePromise } from '@xylabs/react-promise'
import type { BoundWitness } from '@xyo-network/boundwitness'
import type { BoundWitnessDivinerQueryPayload } from '@xyo-network/diviner-boundwitness-model'
import { BoundWitnessDivinerQuerySchema } from '@xyo-network/diviner-boundwitness-model'
import type { WithMeta } from '@xyo-network/payload'

import { useSelectedBoundWitnessDiviner } from './useSelectedBoundWitnessDiviner.ts'

export const useBlocks = (limit = 50): [BoundWitness[] | null | undefined, Error | undefined] => {
  const [selectedBoundWitnessDiviner, selectedBoundWitnessDivinerError] = useSelectedBoundWitnessDiviner()

  const [blocks, blocksError] = usePromise(async () => {
    if (selectedBoundWitnessDiviner) {
      const query: BoundWitnessDivinerQueryPayload = { limit, schema: BoundWitnessDivinerQuerySchema }
      return (await selectedBoundWitnessDiviner.divine([query])) as WithMeta<BoundWitness>[]
    }
  }, [selectedBoundWitnessDiviner, limit])

  return [blocks, blocksError ?? selectedBoundWitnessDivinerError]
}
