import type { DefaultColorScheme } from '@mui/material/styles/createThemeWithVars.js'
import type {} from '@mui/material/themeCssVarsAugmentation'
import { InvertibleMuiThemeProvider } from '@xylabs/react-invertible-theme'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'

import { ThemeCssVars } from './ThemeCssVars.ts'

export const AppOsThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const initialValue: DefaultColorScheme | 'system' = useMemo(() => {
    const validValues: DefaultColorScheme[] = ['light', 'dark']
    const savedValue = localStorage.getItem('mui-mode')
    if (savedValue && validValues.includes(savedValue as DefaultColorScheme)) return savedValue as DefaultColorScheme
    return 'system'
  }, [])

  return (
    <InvertibleMuiThemeProvider theme={ThemeCssVars} defaultMode={initialValue}>
      {children}
    </InvertibleMuiThemeProvider>
  )
}
