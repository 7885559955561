import type { Theme } from '@mui/material'
import { CardContent, useMediaQuery } from '@mui/material'
import { useAsyncEffect } from '@xylabs/react-async-effect'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { PageCard } from '@xyo-network/react-card'
import { WebAppPage } from '@xyo-network/react-webapp'
import type { SchemaPayload } from '@xyo-network/schema-payload-plugin'
import { SchemaCache } from '@xyo-network/sdk-utils'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { PayloadListCardWithProvider } from '../../../../components/index.ts'
import { PayloadListProvider } from '../../../../contexts/index.ts'
import { DynamicBreadcrumbs } from '../../../shared/index.ts'
import { SchemaJsonCard } from '../../components/index.ts'

export const SchemaDetailsPage: React.FC = () => {
  const { schema } = useParams()
  const [payload, setPayload] = useState<SchemaPayload>()

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const flexDirection = isSmall ? 'column' : 'row'
  useAsyncEffect(
    async (mounted) => {
      if (schema && !payload) {
        const entry = await SchemaCache.instance.get(schema)
        if (mounted()) {
          setPayload(entry?.payload)
        }
      }
    },
    [schema, payload],
  )

  return schema
    ? (
        <WebAppPage title={schema} breadcrumbs={<DynamicBreadcrumbs />} variant="fixed">
          <PageCard subheader={schema} title="Schema" sx={{ height: '100%' }}>
            <CardContent sx={{
              alignItems: 'stretch', columnGap: 2, display: 'flex', flexDirection, height: '100%', overflowY: 'hidden', rowGap: 2,
            }}
            >
              <FlexGrowCol alignItems="stretch" justifyContent="center" flexBasis="50%" maxWidth={isSmall ? '100%' : '50%'}>
                <SchemaJsonCard sx={{ height: '100%' }} />
              </FlexGrowCol>
              <FlexGrowCol alignItems="stretch" justifyContent="center">
                <PayloadListProvider>
                  <PayloadListCardWithProvider sx={{ height: '100%' }} />
                </PayloadListProvider>
              </FlexGrowCol>
            </CardContent>
          </PageCard>
        </WebAppPage>
      )
    : null
}
