import {
  Box, Button, Paper,
} from '@mui/material'
import { JsonViewerEx } from '@xyo-network/react-payload-raw-info'
import { WebAppPage } from '@xyo-network/react-webapp'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const JsonPage: React.FC = () => {
  const navigate = useNavigate()
  const json = {}
  return (
    <WebAppPage title="JSON">
      <Paper>
        <Box bgcolor="white" margin={2} padding={2}>
          <JsonViewerEx value={json} />
        </Box>
        <Box display="flex" justifyContent="space-between" margin={1}>
          <Button onClick={() => navigate('..')}>Back</Button>
        </Box>
      </Paper>
    </WebAppPage>
  )
}

export { JsonPage }
