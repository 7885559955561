import { Typography } from '@mui/material'
import { ErrorRender } from '@xylabs/react-common'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import {
  CardContentEx, CardEx, PageCard,
} from '@xyo-network/react-card'
import { useNetwork } from '@xyo-network/react-network'
import { WebAppPage } from '@xyo-network/react-webapp'
import type { SchemaPayload } from '@xyo-network/schema-payload-plugin'
import React from 'react'

import {
  DynamicBreadcrumbs, PageCardWithAddresses, useAddressesWithCustomNames, useSelectedAddress,
} from '../../../shared/index.ts'
import { SchemaLoadedList } from './SchemaLoadedList.tsx'

export interface SchemaLoadedViewerProps extends FlexBoxProps {
  schema: SchemaPayload
}

export const SchemaLoadedPage: React.FC = () => {
  const { network } = useNetwork()

  return (
    <WebAppPage title="Schemas" breadcrumbs={<DynamicBreadcrumbs />} variant="fixed">
      <PageCard
        subheader={(
          <Typography>
            Browse schemas seen on the
            {network?.name}
            {' '}
            network
          </Typography>
        )}
        title="Schemas"
      >
        <CardEx
          sx={{
            backgroundColor: 'transparent',
            backgroundImage: 'none',
          }}
        >
          <CardContentEx variant="scrollable" sx={{ px: 0 }}>
            <SchemaLoadedList justifyContent="flex-start" alignItems="stretch" />
          </CardContentEx>
        </CardEx>
      </PageCard>
    </WebAppPage>
  )
}
