import { compact } from '@xylabs/lodash'
import { usePromise } from '@xylabs/react-promise'
import type { BoundWitness } from '@xyo-network/boundwitness'
import type { DivinerInstance } from '@xyo-network/diviner'
import type { AddressHistoryQueryPayload } from '@xyo-network/diviner-address-history-model'
import { AddressHistoryQuerySchema } from '@xyo-network/diviner-address-history-model'
import type { AddressPayload } from '@xyo-network/module-model'
import type { WithMeta } from '@xyo-network/payload'
import type { ModuleFromNodeConfig } from '@xyo-network/react-modules'
import { useDivinerFromNode, useDivinersFromNode } from '@xyo-network/react-modules'
import { useMemo } from 'react'

export const useDivinersFromNodeByConfigSchema = (
  schema?: string,
  config?: ModuleFromNodeConfig,
): [DivinerInstance[] | undefined, Error | undefined] => {
  const [allDiviners, allDivinersError] = useDivinersFromNode(undefined, config)

  const diviners = useMemo(() => {
    if (allDiviners && schema) {
      return allDiviners?.filter(diviner => diviner.config.schema === schema)
    }
  }, [allDiviners, schema])
  return [diviners, allDivinersError]
}

export const useAddressBlocks = (
  address?: AddressPayload | null,
  addressHistoryDiviner?: string | DivinerInstance,
  config?: ModuleFromNodeConfig,
): [BoundWitness[] | undefined, Error | undefined] => {
  const [addressHistoryDivinerInstance, addressHistoryDivinerError] = useDivinerFromNode(addressHistoryDiviner, config)

  const [blocks, error] = usePromise(async () => {
    if (address === null) {
      return
    }
    if (address?.address && addressHistoryDivinerInstance) {
      const query: AddressHistoryQueryPayload = {
        address: [address.address],
        schema: AddressHistoryQuerySchema,
      }
      return compact(await addressHistoryDivinerInstance?.divine([query])) as WithMeta<BoundWitness>[]
    }
  }, [addressHistoryDivinerInstance, address])

  return [blocks, error ?? addressHistoryDivinerError]
}
