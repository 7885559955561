import { SettingsApplicationsRounded, Widgets } from '@mui/icons-material'
import {
  IconButton, Paper, Stack, useMediaQuery, useTheme,
} from '@mui/material'
import { useAppSettings } from '@xylabs/react-app-settings'
import { DarkModeIconButtonForColorScheme } from '@xylabs/react-invertible-theme'
import type { ApplicationAppBarProps } from '@xylabs/sdk-react'
import { useMenuItemsShared } from '@xylabs/sdk-react'
import {
  SearchBar,
  XyoApplicationAppBar,
  XyoContextToolbar,
  XyoSystemToolbar,
} from '@xyo-network/react-appbar'
import { WalletAccountSelect } from '@xyo-network/react-wallet'
import React, { useState } from 'react'
import {
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom'

import { useFavoriteAddresses } from '../../../favorites/index.ts'
import { MobileDialog } from './components/index.ts'
import { ExploreMenuItems } from './ExploreMenuItems.tsx'

export interface PortalAppBar extends ApplicationAppBarProps {
  hideArchiveFilter?: boolean
}

export const PortalAppBar: React.FC<PortalAppBar> = (props) => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const location = useLocation()
  const { onMenuItemToggle } = useMenuItemsShared()
  const { maxAccounts, navigationType } = useAppSettings()
  const { addressNames } = useFavoriteAddresses()

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState(false)

  const nodeUrl = document.location.hostname.startsWith('beta.') ? 'https://beta.node.xyo.network' : 'https://node.xyo.network'
  const menuItems = isSmall || navigationType === 'menu' ? <ExploreMenuItems alignItems="stretch" /> : <ExploreMenuItems alignItems="stretch" />

  return (
    <XyoApplicationAppBar
      responsive={false}
      contextToolbar={<XyoContextToolbar version="3.0" />}
      systemToolbar={(
        <XyoSystemToolbar
          menuItems={menuItems}
          hideNetworkSelect={isSmall}
          networkSelectProps={{ responsive: isSmall }}
          darkModeButton={false}
          precedingChildren={
            isSmall
              ? (
                  <>
                    <IconButton onClick={() => setOpen(true)}>
                      <SettingsApplicationsRounded htmlColor="white" fontSize="large" />
                    </IconButton>
                    <MobileDialog addressNames={addressNames} maxAccounts={maxAccounts} open={open} setOpen={setOpen} />
                  </>
                )
              : (
                  <Stack direction="row" gap={2} marginRight={1}>
                    {/* <Paper variant="elevation">
                      <ArchivistSelect size="small" sx={{ minWidth: 'calc(100vw / 4)' }} />
                    </Paper> */}
                    <Paper variant="elevation">
                      <WalletAccountSelect
                        iconOnly={isSmall}
                        addressNames={addressNames}
                        icons
                        maxAccounts={maxAccounts}
                        showFavorite
                        size="small"
                        sx={{ minWidth: 'calc(100vw / 4)' }}
                      />
                    </Paper>
                  </Stack>
                )
          }
          onMenuToggle={onMenuItemToggle}
        >
          <IconButton color="inherit" href={nodeUrl} target="_blank">
            <Widgets />
          </IconButton>
          <DarkModeIconButtonForColorScheme />
        </XyoSystemToolbar>
      )}
      {...props}
    >
      <SearchBar
        flexGrow={1}
        onSearch={(term) => {
          if (term) {
            navigate(`/${term.trim()}?search=true`)
          }
        }}
        defaultValue={params.get('search') ? location.pathname.replace('/', '') : ''}
      />
    </XyoApplicationAppBar>
  )
}
