import { ActiveBoundWitnessProvider } from '@xyo-network/react-address-history'
import { RootStorageArchivist } from '@xyo-network/react-standard-node'
import type { PropsWithChildren } from 'react'
import React from 'react'

import { PayloadDrawerWithRouting } from '../PayloadDrawer/index.ts'

export const AddressHistoryProviders: React.FC<PropsWithChildren> = ({ children }) => (
  <ActiveBoundWitnessProvider archivist={RootStorageArchivist}>
    <PayloadDrawerWithRouting>{children}</PayloadDrawerWithRouting>
  </ActiveBoundWitnessProvider>
)
