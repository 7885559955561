import type { CardProps } from '@mui/material'
import {
  Card, CardHeader, LinearProgress, Typography,
} from '@mui/material'
import type { Address } from '@xylabs/hex'
import { ErrorRender } from '@xylabs/react-common'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol, FlexRow } from '@xylabs/react-flexbox'
import { CardContentEx } from '@xyo-network/react-card'
import { PayloadTable } from '@xyo-network/react-payload-table'
import { useSchema } from '@xyo-network/react-schema'
import React, { useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import {
  PayloadListProvider, useNewPayloads, usePayloadList,
  useSchemaStatsFilter,
} from '../../contexts/index.ts'
import { SchemaFilterChip } from '../schema/index.ts'

export const PayloadListCardHeaderFlex: React.FC<FlexBoxProps> = ({ ...props }) => (
  <FlexRow justifyContent="start" flexWrap="wrap" columnGap={1} {...props}>
    <Typography variant="h5">Recent Payloads</Typography>
    <SchemaFilterChip />
  </FlexRow>
)

const useFindAddress = () => {
  const { address: addressFromParams } = useParams()
  const [params] = useSearchParams()
  return (addressFromParams ?? params.get('address') ?? undefined) as Address | undefined
}

const useFindSchema = () => {
  const { schema: schemaFromContext } = useSchema()
  const { schema: schemaFromParams } = useParams()
  const { schemaFilter: schemaFromFilterContext } = useSchemaStatsFilter()
  return schemaFromFilterContext ?? schemaFromParams ?? schemaFromContext
}

export const PayloadListCard: React.FC<CardProps> = (props) => {
  const schema = useFindSchema()
  const address = useFindAddress()
  const [error, setError] = useState<Error>()

  const {
    loading, scrollRef, scrollToTop, totalPayloads, count,
  } = usePayloadList()

  const { xyoError } = useNewPayloads(schema, address)

  useMemo(() => {
    setError(xyoError)
  }, [xyoError])

  return (
    <Card {...props}>
      <CardHeader title={<PayloadListCardHeaderFlex />} />
      <CardContentEx variant="scrollable" style={{ height: '100%', justifyContent: 'start' }} scrollToTop={scrollToTop} ref={scrollRef}>
        <ErrorRender error={error} onCancel={() => setError(undefined)}>
          {totalPayloads
            ? <PayloadTable ref={scrollRef} maxSchemaDepth={2} exploreDomain="" loading={loading} count={count} payloads={totalPayloads} />
            : (
                <FlexGrowCol minHeight="100%">
                  <LinearProgress />
                </FlexGrowCol>
              )}
        </ErrorRender>
      </CardContentEx>
    </Card>
  )
}

export const PayloadListCardWithProvider: React.FC<CardProps> = props => (
  <PayloadListProvider>
    <PayloadListCard {...props} />
  </PayloadListProvider>
)
