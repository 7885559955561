import { Close as CloseIcon } from '@mui/icons-material'
import { IconButton, SwipeableDrawer } from '@mui/material'
import type { Hash } from '@xylabs/hex'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol, FlexRow } from '@xylabs/react-flexbox'
import { useActiveBoundWitness } from '@xyo-network/react-address-history'
import { useWeakArchivistFromNode } from '@xyo-network/react-modules'
import { PayloadProvider } from '@xyo-network/react-payload'
import { RootStorageArchivist } from '@xyo-network/react-standard-node'
import type { PropsWithChildren } from 'react'
import React, {
  forwardRef, useMemo, useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { RenderPayload } from './RenderPayload.tsx'

export type PayloadDrawerProps = PropsWithChildren<FlexBoxProps>

export const PayloadDrawer = forwardRef<HTMLDivElement, PayloadDrawerProps>(({ children, ...props }, ref) => {
  const [archivist] = useWeakArchivistFromNode(RootStorageArchivist)
  const navigate = useNavigate()
  const { hash: payloadHashParam, address: addressParam } = useParams()
  const { activeBoundWitnessHash } = useActiveBoundWitness()

  const [open, setOpen] = useState(false)

  useMemo(() => {
    setOpen(!!payloadHashParam)
  }, [payloadHashParam])

  const navigateBack = () => {
    navigate(`/address/${addressParam}/${activeBoundWitnessHash}`)
  }

  return (
    <PayloadProvider archivist={archivist?.deref()} hash={payloadHashParam as Hash}>
      <FlexGrowCol alignItems="stretch" ref={ref} {...props}>
        <SwipeableDrawer anchor="right" open={open} onClose={navigateBack} onOpen={() => setOpen(true)}>
          <FlexGrowCol role="presentation" justifyContent="start" p={2} rowGap={2}>
            <FlexRow alignContent="start" justifyContent="start" width="100%">
              <IconButton onClick={navigateBack}>
                <CloseIcon />
              </IconButton>
            </FlexRow>
            <RenderPayload />
          </FlexGrowCol>
        </SwipeableDrawer>
        {children}
      </FlexGrowCol>
    </PayloadProvider>
  )
})

PayloadDrawer.displayName = 'PayloadDrawer'
