import { CardContent } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import { PageCard } from '@xyo-network/react-card'
import { useNetwork } from '@xyo-network/react-network'
import { WebAppPage } from '@xyo-network/react-webapp'
import React from 'react'

import { DynamicBreadcrumbs } from '../../shared/index.ts'

export const ArchivistListPage = () => {
  const { network } = useNetwork()

  return (
    <WebAppPage title="Archivists" breadcrumbs={<DynamicBreadcrumbs />}>
      <PageCard subheader={`The archivists on the ${network?.name} network that you can detect`} title="Archivist">
        <FlexCol>
          <CardContent>
            {network?.nodes
              ? (
                  <FlexCol alignItems="flex-start">
                    {network.nodes
                      .filter(node => node.type === 'archivist')
                      .map(node => (
                        <LinkEx key={node.slug} to={`/archivist/${node.slug}`}>
                          {node.slug}
                        </LinkEx>
                      ))}
                  </FlexCol>
                )
              : <FlexRow marginY={16} busy></FlexRow>}
          </CardContent>
        </FlexCol>
      </PageCard>
    </WebAppPage>
  )
}
