import {
  alpha, darken, extendTheme, lighten,
} from '@mui/material'

declare module '@mui/material/styles' {
  interface PaletteOptions {
    iconBgGradientBuilder: (baseColor: string) => string
    iconBgHoverGradientBuilder: (baseColor: string) => string
    iconGradient: string
    iconGradientHover: string
  }
  interface Palette {
    iconBgGradientBuilder: (baseColor: string) => string
    iconBgHoverGradientBuilder: (baseColor: string) => string
    iconGradient: string
    iconGradientHover: string
  }
}

export const defaultIconBackground = '#A060E0'
export const defaultIconBackgroundHover = '#bdade8'

const DynamicIconBgGradientBuilder = {
  iconBgGradientBuilder: (baseColor: string) => `linear-gradient(148deg, ${baseColor} 0%, ${darken(baseColor, 0.3)} 100%);`,
  iconBgHoverGradientBuilder: (baseColor: string) => `linear-gradient(148deg, ${lighten(baseColor, 0.5)} 0%, ${lighten(baseColor, 0.2)} 100%);`,
}

const StaticIconBgGradient = {
  iconGradient: `linear-gradient(148deg, ${defaultIconBackground} 0%, ${darken(defaultIconBackground, 0.35)} 100%);`,
  iconGradientHover: `linear-gradient(148deg, ${defaultIconBackgroundHover} 0%, ${darken(defaultIconBackgroundHover, 0.35)} 100%);`,
}

const lightPalette = {
  background: {
    default: '#FFFFFF',
    paper: '#FAFAFA',
  },
  error: { main: '#BA1A1A' },
  ...DynamicIconBgGradientBuilder,
  ...StaticIconBgGradient,
  primary: { main: '#100029' },
  secondary: { main: '#A060E0' },
  success: { main: '#6bcd23' },
  text: {
    disabled: '#49454D',
    primary: '#1D1B1E',
    secondary: '#49454D',
  },
  warning: { main: '#ffc805' },
}
const darkPalette = {
  background: {
    default: '#1D1B1E',
    paper: '#49454D',
  },
  error: { main: '#ffb4ab' },
  ...DynamicIconBgGradientBuilder,
  ...StaticIconBgGradient,
  primary: { main: '#C9BFFA' },
  secondary: { main: '#C9C3DA' },
  text: {
    disabled: '#a5acdb',
    primary: '#F4F4F4',
    secondary: '#daddf2',
  },
}

export const ThemeCssVars = extendTheme({
  colorSchemes: {
    dark: { palette: darkPalette },
    light: { palette: lightPalette },
  },
  components: {
    MuiAccordion: { styleOverrides: { root: { boxShadow: 'none' } } },
    MuiAccordionSummary: { styleOverrides: { root: { boxShadow: 'none' } } },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          WebkitBackdropFilter: 'blur(20px)',
          backdropFilter: 'blur(20px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.2),
          boxShadow: 'none',
          color: '#1D1B1E',
          [theme.getColorSchemeSelector('dark')]: { color: '#CBC4CE' },
        }),
      },
    },
    MuiCard: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          // This is to get rid of the extra padding at the end of Card Content that misaligns padding when there's only 1 item
          '&:last-child': { paddingBottom: '16px' },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
      },
    },
    MuiChip: { styleOverrides: { root: { fontWeight: 400 } } },
    MuiContainer: { styleOverrides: { root: { maxWidth: 'xl' } } },
    MuiLink: {
      defaultProps: { underline: 'none' },
      styleOverrides: { root: { '&:hover': { filter: 'brightness(75%)' } } },
    },
    MuiPaper: { defaultProps: { elevation: 0 } },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontFamily: 'monospace',
          whiteSpace: 'nowrap',
        },
        head: {
          fontWeight: 700,
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  spacing: 12,
  typography: {
    allVariants: { fontFamily: ['Manrope', 'san-serif'].join(',') },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
    h1: { fontSize: '3.2rem' },
    h2: { fontSize: '2.7rem' },
    h3: { fontSize: '2.24rem' },
    h4: { fontSize: '2rem' },
    h5: { fontSize: '1.5rem' },
    h6: { fontSize: '1.2rem' },
    subtitle1: {
      fontWeight: 300,
      opacity: '50%',
      textTransform: 'uppercase',
    },
    subtitle2: { opacity: '50%' },
  },
})
