import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import { ApiEmbedPluginCard } from '@xyo-network/react-embed'
import { usePayload } from '@xyo-network/react-payload'
import { LoadResult, useListMode } from '@xyo-network/react-shared'
import React from 'react'

import { useRender } from '../../contexts/index.ts'
import { PayloadActions } from './PayloadActions.tsx'

export const PayloadRender: React.FC<FlexBoxProps> = (props) => {
  const { payload, setPayload } = usePayload()
  const { plugin } = useRender()
  const { listMode } = useListMode()

  return (
    <FlexCol flexGrow={1} alignItems="stretch" justifyContent="start" rowGap={4} {...props}>
      <LoadResult searchResult={payload} notFound={payload === null}>
        <PayloadActions payload={payload ?? undefined} />
        <ApiEmbedPluginCard
          style={{ height: plugin.fillContainer ? '100%' : 'inherit' }}
          huriPayload={payload ?? ''}
          plugins={[plugin]}
          embedPluginConfig={{ listMode }}
          busyMinimum={500}
          busy={payload === undefined}
          onRefresh={() => setPayload?.(undefined)}
        />
      </LoadResult>
    </FlexCol>
  )
}
