import { Typography, useTheme } from '@mui/material'
import { ErrorRender } from '@xylabs/react-common'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { useRenderSpinCheck } from '@xylabs/react-render-spin-check'
import { BlockTable } from '@xyo-network/react-block'
import {
  CardContentEx, CardEx, PageCard,
} from '@xyo-network/react-card'
import { useNetwork } from '@xyo-network/react-network'
import { WebAppPage } from '@xyo-network/react-webapp'
import React, { useMemo, useState } from 'react'

import { DynamicBreadcrumbs } from '../../shared/index.ts'
import { useBlocks } from '../hooks/index.ts'

export const BlockListPage: React.FC = () => {
  const name = useMemo(() => ({ name: 'BlockListPage' }), [])
  useRenderSpinCheck(name)

  const { network } = useNetwork()
  const [defaultBlocks, defaultBlocksError] = useBlocks()
  const [showError, setShowError] = useState(true)

  const theme = useTheme()

  return (
    <WebAppPage busy={(defaultBlocks === undefined) && (defaultBlocksError === undefined)} title="Blocks" breadcrumbs={<DynamicBreadcrumbs />} variant="fixed">
      <ErrorRender
        error={
          showError ? defaultBlocksError : undefined
        }
        onCancel={() => {
          console.log('cancel')
          setShowError(false)
        }}
      >
        <PageCard
          subheader={(
            <Typography>
              Browse blocks seen on the
              {network?.name}
              {' '}
              network
            </Typography>
          )}
          title="Blocks"
        >
          <CardEx
            style={{
              backgroundColor: 'transparent',
              backgroundImage: 'none',
            }}
          >
            <CardContentEx variant="scrollable" sx={{ px: 0 }}>
              <FlexGrowCol bgcolor="red" alignItems="stretch">
                <BlockTable blocks={defaultBlocks} sx={{ backgroundColor: theme.palette.background.default }} />
              </FlexGrowCol>
            </CardContentEx>
          </CardEx>
        </PageCard>
      </ErrorRender>
    </WebAppPage>
  )
}
