import {
  Alert, AlertTitle, CardContent, Snackbar,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { NumberStatus } from '@xylabs/react-number-status'
import { PageCard } from '@xyo-network/react-card'
import { useNetwork } from '@xyo-network/react-network'
import { WebAppPage } from '@xyo-network/react-webapp'
import type { ApiError } from '@xyo-network/sdk-utils'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { DynamicBreadcrumbs } from '../../shared/index.ts'

export const ArchivistDetailPage = () => {
  const { archivist } = useParams()
  const { network } = useNetwork()
  const uri = network?.nodes?.find(node => node.slug === archivist)?.uri

  const [errors, setErrors] = useState<ApiError[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blockCount, setBlockCount] = useState<number | null>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [payloadCount, setPayloadCount] = useState<number | null>()

  return (
    <WebAppPage title={`${archivist} Details`} breadcrumbs={<DynamicBreadcrumbs />}>
      <PageCard subheader={uri} title={archivist}>
        <CardContent>
          <FlexRow mb={3}>
            <NumberStatus rounded margin={2} title="Blocks" value={blockCount ?? undefined} />
            <NumberStatus rounded margin={2} title="Payloads" value={payloadCount ?? undefined} />
          </FlexRow>
          <FlexCol rowGap={2}>
            <Alert severity="info">
              <AlertTitle>Coming Soon</AlertTitle>
            </Alert>
            <ButtonEx variant="contained" to="/archive">
              My Archives
            </ButtonEx>
          </FlexCol>
          {errors.map((error, index) => (
            <Snackbar key={index} open={errors.length > 0} autoHideDuration={30_000} onClose={() => setErrors([])}>
              <Alert onClose={() => setErrors([])} severity="error">
                {error?.message}
              </Alert>
            </Snackbar>
          ))}
        </CardContent>
      </PageCard>
    </WebAppPage>
  )
}
