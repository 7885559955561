import type { Address, Hash } from '@xylabs/hex'
import { useAsyncEffect } from '@xylabs/react-async-effect'
import { TYPES } from '@xyo-network/node-core-types'
import type { Payload, Schema } from '@xyo-network/payload'
import { PayloadBuilder } from '@xyo-network/payload'
import { useDivinerFromNode } from '@xyo-network/react-modules'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'

import { usePayloadFindFilter } from '../../modules/payload/hooks/index.ts'
import { usePayloadList } from './use.tsx'

export const useNewPayloads = (schema?: Schema, address?: Address, limit = 100, nameOrAddress = TYPES.PayloadDiviner) => {
  // TODO - offset support requires a type change to string in the query payload so we can use hashes
  const {
    offset, setLoading, setOffset, totalPayloads, updateTotalPayloads, resetList,
  } = usePayloadList()

  const [newPayloads, setNewPayloads] = useState<Payload[]>()
  const [xyoError, setXyoError] = useState<Error>()
  const [newPayloadsState, setNewPayloadsState] = useState<Payload[]>()
  const [lastHash, setLastHash] = useState<Hash | undefined>()

  const filterProps = useMemo(() => ({
    address, limit, schemas: schema ? [schema] : undefined,
  }), [address, limit, schema])
  const filter = usePayloadFindFilter(filterProps)

  const [payloadDiviner] = useDivinerFromNode(nameOrAddress)

  useAsyncEffect(
    async () => {
      if (payloadDiviner && filter) {
        try {
          const payloads = await payloadDiviner.divine([filter])
          setNewPayloads(payloads)
          setXyoError(undefined)
        } catch (e) {
          setXyoError(e as Error)
          setNewPayloads(undefined)
        }
      }
    },
    [payloadDiviner, filter],
  )

  useMemo(() => {
    setNewPayloadsState(newPayloads)
  }, [newPayloads])

  /**
   * Cases to handle
   * 1. Total Payloads is empty and needs new payloads - done
   * 2. Total Payloads is filled and needs new payloads - tbd
   * 3. Total Payloads is filled and needs to be emptied before new payloads (schema change) - done
   */

  useMemo(() => {
    // reset on any schema change
    resetList?.()
    setNewPayloadsState(undefined)
    // reset offset when schema or archive changes
    setOffset?.(undefined)
  }, [address, schema, setOffset, resetList])

  useEffect(() => {
    if (xyoError || newPayloadsState) {
      setLoading?.(false)
    }
  }, [xyoError, setLoading, newPayloadsState])

  useAsyncEffect(
    async () => {
      const lastPayload = newPayloadsState?.at(-1)
      if (lastPayload) {
        setLastHash(await PayloadBuilder.dataHash(lastPayload))
      }
    },
    [newPayloadsState],
  )

  const newLastPayload = useCallback(() => {
    return offset && lastHash ? lastHash !== offset : undefined
  }, [lastHash, offset])

  useEffect(() => {
    if (newPayloadsState && totalPayloads?.length === 0) {
      updateTotalPayloads?.(newPayloadsState)
    }
  }, [newLastPayload, newPayloadsState, totalPayloads?.length, updateTotalPayloads])

  const fetchMorePayloads = () => {
    setLoading?.(true)
  }

  return {
    fetchMorePayloads, newPayloads: newPayloadsState, xyoError,
  }
}
