import type { Hash } from '@xylabs/hex'
import { ErrorRender } from '@xylabs/react-common'
import { useWeakArchivistFromNode } from '@xyo-network/react-modules'
import { PayloadProvider } from '@xyo-network/react-payload'
import { RootStorageArchivist } from '@xyo-network/react-standard-node'
import { WebAppNotFoundPage } from '@xyo-network/react-webapp'
import React, { useMemo, useState } from 'react'
import type { RoutesProps } from 'react-router-dom'
import {
  Route, Routes, useParams,
} from 'react-router-dom'

import { BreadcrumbProvider } from '../../shared/index.ts'
import { PayloadRenderWithRouteProvider } from '../components/index.ts'
import { PayloadRenderPage } from '../pages/index.ts'

export const PayloadRenderRoutes: React.FC<RoutesProps> = (props) => {
  const { hash } = useParams()
  const [error, setError] = useState<Error>()
  const [archivist, archivistError] = useWeakArchivistFromNode(RootStorageArchivist)

  useMemo(() => {
    setError(archivistError)
  }, [archivistError])

  return (
    <PayloadProvider archivist={archivist?.deref()} hash={hash as Hash}>
      <ErrorRender error={error} onCancel={() => setError(undefined)}>
        <PayloadRenderWithRouteProvider>
          <BreadcrumbProvider name={hash === undefined ? '[missing search value]' : hash} to={hash}>
            <Routes {...props}>
              <Route path="/" element={<PayloadRenderPage />} />
              <Route path="/:render/:pointerResultHash/:pointerRender" element={<PayloadRenderPage />} />
              <Route path="/:render/*" element={<PayloadRenderPage />} />
              <Route path="*" element={<WebAppNotFoundPage />} />
            </Routes>
          </BreadcrumbProvider>
        </PayloadRenderWithRouteProvider>
      </ErrorRender>
    </PayloadProvider>
  )
}
