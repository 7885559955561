import { HashSelectionHistoryProvider, NestedBoundWitnessesProvider } from '@xyo-network/react-address-history'
import { useWeakArchivistFromNode } from '@xyo-network/react-modules'
import { RootStorageArchivist } from '@xyo-network/react-standard-node'
import type { PropsWithChildren } from 'react'
import React from 'react'

export type HashSelectionHistoryArchivistProps = PropsWithChildren

export const HashSelectionHistoryArchivist: React.FC<HashSelectionHistoryArchivistProps> = ({ children }) => {
  const [archivist] = useWeakArchivistFromNode(RootStorageArchivist)
  return (
    <HashSelectionHistoryProvider archivist={archivist?.deref() ?? undefined}>
      <NestedBoundWitnessesProvider>{children}</NestedBoundWitnessesProvider>
    </HashSelectionHistoryProvider>
  )
}
