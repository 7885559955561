import type { PayloadDivinerQueryPayload } from '@xyo-network/diviner-payload-model'
import { PayloadDivinerQuerySchema } from '@xyo-network/diviner-payload-model'
import { useMemo } from 'react'

export const usePayloadFindFilter = ({
  limit, schemas, offset, address,
}: Omit<PayloadDivinerQueryPayload, 'schema'>) => {
  const filter = useMemo(() => {
    const filterBase: PayloadDivinerQueryPayload = {
      address, limit, schema: PayloadDivinerQuerySchema,
    }
    if (schemas) {
      filterBase.schemas = schemas
    }
    if (offset !== undefined) {
      filterBase.offset = offset
    }
    return filterBase
  }, [address, limit, offset, schemas])

  return filter
}
