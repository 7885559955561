import { generateMnemonic } from '@scure/bip39'
import { wordlist } from '@scure/bip39/wordlists/english'
import { useAppSettings } from '@xylabs/react-app-settings'
import type { WalletProviderProps } from '@xyo-network/react-wallet'
import { useWallet, WalletProvider } from '@xyo-network/react-wallet'
import type { PropsWithChildren } from 'react'
import React from 'react'

export const Wallet: React.FC<PropsWithChildren<WalletProviderProps>> = ({ children, ...props }) => {
  const { seedPhrase } = useAppSettings()

  const [wallet] = useWallet({ mnemonic: seedPhrase ?? generateMnemonic(wordlist, 256) })

  console.log(`Wallet.address: ${wallet?.address}`)
  // console.log(`Wallet.seedPhrase: ${seedPhrase}`)

  return (
    <WalletProvider rootWallet={wallet} {...props}>
      {children}
    </WalletProvider>
  )
}
