import type { Address } from '@xylabs/hex'
import { ErrorRender } from '@xylabs/react-common'
import type { BoundWitness } from '@xyo-network/boundwitness'
import { type AddressPayload, AddressSchema } from '@xyo-network/module-model'
import { useAddressHistory } from '@xyo-network/react-address-history'
import { useWallet } from '@xyo-network/react-wallet'
import { WebAppPage } from '@xyo-network/react-webapp'
import React, {
  useEffect, useMemo, useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import type { SharableAddressAutoCompleteProps } from '../../shared/index.ts'
import {
  BreadcrumbProvider,
  DynamicBreadcrumbs,
  PageCardWithAddresses,
} from '../../shared/index.ts'
import { AddressHistoryProviders, AddressHistoryRenderer } from '../components/index.ts'

const autoCompleteProps: SharableAddressAutoCompleteProps = { showHistoryButton: false }

export const AddressHistoryPage = () => {
  const { address: addressParam } = useParams()
  const [wallet] = useWallet()
  const selectedAddress = useMemo(() => {
    const rawAddress = addressParam ?? wallet?.address
    const addressPayload: AddressPayload | undefined = rawAddress ? { address: rawAddress as Address, schema: AddressSchema } : undefined
    return addressPayload
  }, [addressParam, wallet])
  const navigate = useNavigate()
  const [addressHistory, error] = useAddressHistory(selectedAddress?.address)
  const [showError, setShowError] = useState(true)

  useEffect(() => {
    if ((addressParam === undefined) && selectedAddress) {
      navigate(`/address/${selectedAddress?.address}`)
    }
  }, [addressParam, selectedAddress, navigate])

  const onChangeWrapped = (value: AddressPayload | null) => {
    navigate(`/address/${value?.address ?? ''}`)
  }

  return (
    <BreadcrumbProvider name={selectedAddress?.address ?? ''} to={selectedAddress?.address}>
      <WebAppPage title={`${selectedAddress?.address ?? 'Address'} History`} breadcrumbs={<DynamicBreadcrumbs />} variant="fixed">
        <ErrorRender
          error={showError ? error : undefined}
          onCancel={() => {
            console.log('cancel')
            setShowError(false)
          }}
        >
          {selectedAddress
            ? (
                <PageCardWithAddresses
                  addresses={[selectedAddress]}
                  onAddressChange={onChangeWrapped}
                  selectedAddress={selectedAddress}
                  autoCompleteProps={autoCompleteProps}
                  title="Browse the history of an address"
                  sx={{ height: '100%' }}
                >
                  <AddressHistoryProviders>
                    <AddressHistoryRenderer addressHistory={addressHistory as BoundWitness[]} />
                  </AddressHistoryProviders>
                </PageCardWithAddresses>
              )
            : null}
        </ErrorRender>
      </WebAppPage>
    </BreadcrumbProvider>
  )
}
