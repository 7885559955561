import { WebAppNotFoundPage } from '@xyo-network/react-webapp'
import React from 'react'
import type { RoutesProps } from 'react-router-dom'
import {
  Route, Routes, useParams,
} from 'react-router-dom'

import { BreadcrumbProvider } from '../shared/index.ts'
import { BlockDetailsPage, BlockListPage } from './pages/index.ts'

export const BlockHashRoutes: React.FC<RoutesProps> = (props) => {
  const { hash } = useParams()
  return (
    <BreadcrumbProvider name={hash ?? '<hash>'} to={hash}>
      <Routes {...props}>
        <Route path="*" element={<BlockDetailsPage />} />
      </Routes>
    </BreadcrumbProvider>
  )
}

export const BlockRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <BreadcrumbProvider name="Blocks" to="block">
      <Routes {...props}>
        <Route path="/" element={<BlockListPage />} />
        <Route path="/recent/" element={<BlockListPage />} />
        <Route path="/hash/:hash/*" element={<BlockHashRoutes />} />
        <Route path="/:hash/*" element={<BlockHashRoutes />} />
        <Route path="*" element={<WebAppNotFoundPage />} />
      </Routes>
    </BreadcrumbProvider>
  )
}
