import { useAsyncEffect } from '@xylabs/react-async-effect'
import type { PackageManifestPayload } from '@xyo-network/manifest'
import { ManifestWrapper } from '@xyo-network/manifest'
import type { MemoryNode } from '@xyo-network/node'
import { NodeProvider } from '@xyo-network/react-modules'
import { useNetwork } from '@xyo-network/react-network'
import { useWallet } from '@xyo-network/react-wallet'
import type { PropsWithChildren } from 'react'
import React, { useState } from 'react'

import globalNodeManifest from './global-node-manifest.json'

export const GlobalNode: React.FC<PropsWithChildren> = ({ children }) => {
  const [globalNode, setGlobalNode] = useState<MemoryNode>()
  const [wallet] = useWallet()
  const { network } = useNetwork()

  useAsyncEffect(
    async () => {
      if (wallet && network) {
        try {
          const nodeWallet = await wallet.derivePath("0'")

          const nodeIndex
            = network.name === 'Main'
              ? 0
              : network.name === 'Kerplunk'
                ? 1
                : 2

          const manifest = new ManifestWrapper(globalNodeManifest as PackageManifestPayload, nodeWallet)
          const node = await manifest.loadNodeFromIndex(nodeIndex)
          console.log(`GlobalNode (should only run once) Nodes: ${node?.address}`)
          console.log(`GlobalNode Node: ${node.address}`)

          setGlobalNode(node)
        } catch (e) {
          const error = e as Error
          console.error(`Error creating global node: ${error.message}`)
          console.error(`Error creating global node: ${error.stack}`)
          throw new Error(`Error creating global node: ${error.message}`)
        }
      }
    },
    [wallet, network],
  )

  return <NodeProvider node={globalNode}>{children}</NodeProvider>
}
