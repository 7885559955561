import { WebAppNotFoundPage } from '@xyo-network/react-webapp'
import React from 'react'
import type { RoutesProps } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import { AddressRoutes } from '../address/index.ts'
import { ArchivistRoutes } from '../archivist/index.ts'
import { BlockRoutes } from '../block/index.ts'
import { NodeRoutes } from '../node/index.ts'
import { PayloadRenderRoutes, PayloadRoutes } from '../payload/index.ts'
import { SchemaRoutes } from '../schema/index.ts'
import { BreadcrumbProvider } from '../shared/index.ts'
import {
  HomePageDashboard, HomePageHeatMap, JsonPage, SettingsPage,
} from './pages/index.ts'

export const RootRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <BreadcrumbProvider name="XYO">
      <Routes {...props}>
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/activity" element={<HomePageHeatMap />} />
        <Route path="/json" element={<JsonPage />} />
        <Route path="/block/*" element={<BlockRoutes />} />
        <Route path="/payload/*" element={<PayloadRoutes />} />
        <Route path="/archivist/*" element={<ArchivistRoutes />} />
        <Route path="/schema/*" element={<SchemaRoutes />} />
        <Route path="/address/*" element={<AddressRoutes />} />
        <Route path="/node/*" element={<NodeRoutes />} />
        <Route path="/:hash/*" element={<PayloadRenderRoutes />} />
        <Route path="/" element={<HomePageDashboard />} />
        <Route path="*" element={<WebAppNotFoundPage />} />
      </Routes>
    </BreadcrumbProvider>
  )
}
