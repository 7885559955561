import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import { useCollapsible } from '@xyo-network/react-shared'
import React from 'react'

import { MenuSections } from './MenuSections.tsx'

export const ExploreMenuItems: React.FC<FlexBoxProps> = ({ ...props }) => {
  const { collapse } = useCollapsible()

  return (
    <FlexCol id="menu-sections-wrap" {...props}>
      <MenuSections collapse={collapse} />
    </FlexCol>
  )
}
