import { ListModeProvider } from '@xyo-network/react-shared'
import React from 'react'

import { PayloadRender } from '../../../payload/components/index.ts'
import { PayloadRenderWithRouteProvider } from '../../../payload/index.ts'

export const RenderPayload = () => {
  return (
    <PayloadRenderWithRouteProvider>
      <ListModeProvider>
        <PayloadRender minWidth="50vw" />
      </ListModeProvider>
    </PayloadRenderWithRouteProvider>
  )
}
