import { CardActions, Grid2 } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { SimpleCard } from '@xyo-network/react-card'
import { WebAppPage } from '@xyo-network/react-webapp'
import React from 'react'

export const HomePageDashboard = () => {
  return (
    <WebAppPage title="Explore" variant="fixed">
      <Grid2 container spacing={1}>
        <Grid2 size={{
          xs: 12, sm: 6, md: 4,
        }}
        >
          <SimpleCard headline="Main Node" subtitle="Public Node hosted by XYO for production data">
            <CardActions>
              <ButtonEx fullWidth variant="outlined" to="/block?network=main">Blocks</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/payload?network=main">Payloads</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/schema?network=main">Schemas</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/node?network=main">Details</ButtonEx>
            </CardActions>
          </SimpleCard>
        </Grid2>
        <Grid2 size={{
          xs: 12, sm: 6, md: 4,
        }}
        >
          <SimpleCard headline="Kerplunk Node" subtitle="Public Node hosted by XYO for test data">
            <CardActions>
              <ButtonEx fullWidth variant="outlined" to="/block?network=kerplunk">Blocks</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/payload?network=kerplunk">Payloads</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/schema?network=kerplunk">Schemas</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/node?network=kerplunk">Details</ButtonEx>
            </CardActions>
          </SimpleCard>
        </Grid2>
        <Grid2 size={{
          xs: 12, sm: 6, md: 4,
        }}
        >
          <SimpleCard headline="Local Node" subtitle="A local Node on your system if one is running">
            <CardActions>
              <ButtonEx fullWidth variant="outlined" to="/block?network=local">Blocks</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/payload?network=local">Payloads</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/schema?network=local">Schemas</ButtonEx>
              <ButtonEx fullWidth variant="outlined" to="/node?network=local">Details</ButtonEx>
            </CardActions>
          </SimpleCard>
        </Grid2>
      </Grid2>
    </WebAppPage>
  )
}
