import type { Address } from '@xylabs/hex'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import type { BoundWitness } from '@xyo-network/boundwitness'
import {
  AddressHistory,
  BlocksQuickTipButton,
  BoundWitnessBoxWithAddressRouter,
  ColumnHeadingTypography,
  ScrollableGridColumn, SelectedBlockQuickTipButton, useActiveBoundWitness, WrappedContainer,
} from '@xyo-network/react-address-history'
import { useEvent } from '@xyo-network/react-event'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { HashSelectionHistoryArchivist } from './Archivist/index.ts'

export interface AddressHistoryRendererProps extends FlexBoxProps {
  addressHistory?: BoundWitness[]
}

export const AddressHistoryRenderer: React.FC<AddressHistoryRendererProps> = ({ addressHistory, ...props }) => {
  const { address } = useParams()
  const navigate = useNavigate()
  const { activeBoundWitnessHash } = useActiveBoundWitness()

  const [ref] = useEvent<HTMLUListElement>((noun, _verb, data) => {
    if (noun === 'boundwitness' && data) {
      navigate(`/address/${address}/${data}`)
    }
  })

  return (
    <WrappedContainer pl={1} pt={2} {...props}>
      <ScrollableGridColumn
        heading={(
          <ColumnHeadingTypography mr={2}>
            Blocks
            {' '}
            <BlocksQuickTipButton fontSize="inherit" address={address} />
          </ColumnHeadingTypography>
        )}
        item
        lg={3}
        md={4}
        sm={4}
        xs={12}
      >
        <AddressHistory address={address as Address} addressHistory={addressHistory} ref={ref} selectable sx={{ pr: 2, py: 2 }} />
      </ScrollableGridColumn>
      <ScrollableGridColumn
        heading={(
          <ColumnHeadingTypography ml={2} mr={2}>
            Active Block History
            {' '}
            <SelectedBlockQuickTipButton fontSize="inherit" boundwitnessHash={activeBoundWitnessHash} />
          </ColumnHeadingTypography>
        )}
        item
        lg={9}
        md={8}
        sm={8}
        xs={12}
        scrollableProps={{
          // account for negative grid margins hiding the glow
          left: 3,
          pl: 2,
          pr: 2,
          pt: 2,
        }}
      >
        <HashSelectionHistoryArchivist>
          <BoundWitnessBoxWithAddressRouter />
        </HashSelectionHistoryArchivist>
      </ScrollableGridColumn>
    </WrappedContainer>
  )
}
