import type { JsonViewerProps } from '@textea/json-viewer'
import { useAppSettings } from '@xylabs/react-app-settings'
import { JsonViewerEx } from '@xyo-network/react-payload-raw-info'
import React from 'react'

export const SchemaLoadedJsonViewer: React.FC<JsonViewerProps> = (props) => {
  const { darkMode } = useAppSettings()

  return <JsonViewerEx theme={darkMode ? 'dark' : 'light'} collapseStringsAfterLength={64} {...props} />
}
