import { Button } from '@mui/material'
import type { ButtonExProps } from '@xylabs/react-button'
import React, { forwardRef } from 'react'

export type SeeHistoryButtonProps = ButtonExProps & {
  value?: string
}

export const AddressHistoryButton = forwardRef<HTMLButtonElement, SeeHistoryButtonProps>(({ value, ...props }, ref) => {
  return (
    <Button ref={ref} variant="contained" disabled={!value} {...props}>
      History
    </Button>
  )
})

AddressHistoryButton.displayName = 'AddressHistoryButton'
