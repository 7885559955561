import type { AutocompleteProps } from '@mui/material'
import {
  CircularProgress, MenuItem, TextField,
} from '@mui/material'
import { FlexRow } from '@xylabs/react-flexbox'
import { Identicon } from '@xylabs/react-identicon'
import type { AddressPayload } from '@xyo-network/module-model'
import { AddressRenderRowBox } from '@xyo-network/react-address-render'
import type { MouseEvent } from 'react'
import React, { useState } from 'react'

import type { AddressWithFavorite } from '../../../types/index.ts'
import { AddressesListbox } from '../AddressesListBox.tsx'
import { AddressStatsChipOnHover } from '../AddressStatsChips.tsx'

type ModifiedAutoCompleteProps = Omit<AutocompleteProps<AddressWithFavorite, false, false, false>, 'options'>

export const AddressAutoCompleteConstantProps = {
  blurOnSelect: true,
  getOptionLabel: (option: AddressPayload) => option.address,
  handleHomeEndKeys: true,
  id: 'address-list-autocomplete',
  loadingText: 'Loading all addresses (sit tight)',
  noOptionsText: 'Unable to find addresses',
  selectOnFocus: true,
  size: 'small' as ModifiedAutoCompleteProps['size'],
}

export const useAddressAutoCompleteProps = (addresses?: AddressWithFavorite[], value?: AddressWithFavorite | null) => {
  const loading = addresses?.length === 0
  const [inputValue, setInputValue] = useState<string>('')
  const [open, setOpen] = useState(false)

  const isOptionEqualToValue: ModifiedAutoCompleteProps['isOptionEqualToValue'] = (option, value) => {
    if (option.address.includes(value.address)) return true
    return false
  }

  const renderInput: ModifiedAutoCompleteProps['renderInput'] = (params) => {
    return (
      <TextField
        {...params}
        slotProps={{
          input: {
            ...params.InputProps,
            endAdornment: (
              <>
                {loading
                  ? <CircularProgress color="inherit" size={20} />
                  : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              <FlexRow mx={1}>
                <Identicon size={16} value={value?.address} />
              </FlexRow>
            ),
          },
        }}
        label="Selected Address"
        onClick={() => setOpen(!open)}
      />
    )
  }

  const renderOption: ModifiedAutoCompleteProps['renderOption'] = (props, option) => {
    return (
      <MenuItem
        {...props}
        onTouchStart={(event) => {
          setOpen(false)
          props.onTouchStart?.(event)
          props.onClick?.(event as unknown as MouseEvent<HTMLLIElement>)
        }}
        onClick={(event) => {
          setOpen(false)
          props.onClick?.(event)
        }}
      >
        <AddressRenderRowBox flexGrow={1} address={option.address} favorite={option.favorite} name={option.address} icons showFavorite>
          <AddressStatsChipOnHover address={option.address} />
        </AddressRenderRowBox>
      </MenuItem>
    )
  }

  const onInputChange: ModifiedAutoCompleteProps['onInputChange'] = (_, newInputValue) => {
    setInputValue(newInputValue)
  }

  return {
    autoCompleteProps: {
      ...AddressAutoCompleteConstantProps,
      ListboxComponent: AddressesListbox,
      inputValue,
      isOptionEqualToValue,
      loading,
      onInputChange,
      renderInput,
      renderOption,
    },
    clickAwayListenerProps: {
      open,
      setOpen,
    },
  }
}
