import { MenuItem } from '@mui/material'
import type { SelectExProps } from '@xylabs/react-select'
import { SelectEx } from '@xylabs/react-select'
import React from 'react'

import { useRender } from '../../contexts/index.ts'

export const RenderSelect: React.FC<SelectExProps<string>> = (props) => {
  const {
    setRender, plugin, canRender,
  } = useRender()

  return (
    <SelectEx value={plugin.slug ?? ''} onChange={event => setRender?.(event.target.value)} {...props}>
      {canRender?.find(configToCheck => configToCheck.slug === plugin.slug)
        ? null
        : (
            <MenuItem key={plugin.slug} value={plugin.slug}>
              {plugin.name}
            </MenuItem>
          )}
      {canRender?.map((config) => {
        return (
          <MenuItem key={config.slug} value={config.slug}>
            {config.name}
          </MenuItem>
        )
      })}
    </SelectEx>
  )
}
