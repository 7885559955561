import type { Hash } from '@xylabs/hex'
import { useRenderSpinCheck } from '@xylabs/react-render-spin-check'
import type { BoundWitness } from '@xyo-network/protocol'
import { BoundWitnessDetailsBox } from '@xyo-network/react-boundwitness-plugin'
import { useEvent } from '@xyo-network/react-event'
import { LoadResult } from '@xyo-network/react-shared'
import { WebAppPage } from '@xyo-network/react-webapp'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useHuri } from '../../../hooks/index.ts'
import { DynamicBreadcrumbs } from '../../shared/index.ts'

export const BlockDetailsPage: React.FC = () => {
  const { hash } = useParams()
  const navigate = useNavigate()

  const name = useMemo(() => ({ name: 'BlockDetailsPage' }), [])
  useRenderSpinCheck(name)

  const bw = useHuri(hash as Hash)

  const [ref] = useEvent<HTMLDivElement>((noun, verb, data) => {
    switch (noun) {
      case 'boundwitness': {
        switch (verb) {
          case 'click': {
            navigate(`/block/hash/${data}`)
            break
          }
        }
        break
      }
      case 'payload': {
        switch (verb) {
          case 'click': {
            navigate(`/payload/hash/${data}`)
            break
          }
        }
        break
      }
      case 'address': {
        switch (verb) {
          case 'click': {
            navigate(`/address/${data}`)
            break
          }
        }
        break
      }
    }
  })

  const block = bw as BoundWitness | undefined
  const notFound = bw === undefined

  return (
    <WebAppPage title={hash} breadcrumbs={<DynamicBreadcrumbs />}>
      <LoadResult<BoundWitness> {...{ notFound, searchResult: block }}>
        {block
          ? <BoundWitnessDetailsBox ref={ref} marginY={2} px={1} payload={block} />
          : null}
      </LoadResult>
    </WebAppPage>
  )
}
