import type { Address } from '@xylabs/hex'
import { cloneDeep } from '@xylabs/lodash'
import { useAsyncEffect } from '@xylabs/react-async-effect'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import { ErrorRender } from '@xylabs/sdk-react'
import { PayloadBuilder } from '@xyo-network/protocol'
import { useNetwork } from '@xyo-network/react-network'
import { useSchemaDefinitions } from '@xyo-network/react-schema'
import type { SchemaPayload } from '@xyo-network/schema-payload-plugin'
import { SchemaSchema } from '@xyo-network/schema-payload-plugin'
import React, { useMemo, useState } from 'react'

import { useSchemaList } from './hooks/index.ts'
import { SchemaLoadedViewer } from './SchemaLoadedViewer.tsx'

type HashSchemaPayloadTuple = [string, SchemaPayload]

const generateSchema = (name?: string) => ({
  definition: {
    $id: name,
    $schema: 'http://json-schema.org/draft-07/schema#',
    additionalProperties: true,
  },
  schema: SchemaSchema,
})

export interface SchemaLoadedListProps extends FlexBoxProps {
  address?: Address
}

export const SchemaLoadedList: React.FC<SchemaLoadedListProps> = ({ address, ...props }) => {
  const [schemaList, schemaListError] = useSchemaList(address)

  const mappedSchemaListNames = useMemo(() => (schemaList ? schemaList.schemas.map(item => ({ name: item })) : undefined), [schemaList])
  const resolvedSchemaPayloads = useSchemaDefinitions(mappedSchemaListNames)

  const [schemaPayloadsList, setSchemaPayloadList] = useState<SchemaPayload[]>()
  const [hashedSchemaPayloadsList, setHashedSchemaPayloadsList] = useState<HashSchemaPayloadTuple[]>([])

  const { network } = useNetwork()

  useMemo(() => {
    setSchemaPayloadList(undefined)
    setHashedSchemaPayloadsList([])
  }, [network])

  // Set initial list of payloads from SchemaListDiviner
  useMemo(() => {
    if (mappedSchemaListNames) {
      const tempList: SchemaPayload[] = []
      for (const { name } of mappedSchemaListNames) {
        const schema = generateSchema(name)
        schema.definition.$id = name
        tempList.push(schema)
      }
      setSchemaPayloadList(tempList)
    }
  }, [mappedSchemaListNames])

  // Replace schema payloads with new ones found via domain configs
  useMemo(() => {
    if (resolvedSchemaPayloads) {
      // const payloadSchema = resolvedSchemaPayloads?.find((payload) => payload.definition.$id === 'network.xyo.payload')
      setSchemaPayloadList(existingSchemaPayloadList =>
        existingSchemaPayloadList?.map((existingSchemaPayload) => {
          const name = existingSchemaPayload.definition.$id
          const matchedResolvedSchemaPayload = resolvedSchemaPayloads?.find(payload => payload.definition.$id === name)
          return matchedResolvedSchemaPayload ? cloneDeep(matchedResolvedSchemaPayload) : existingSchemaPayload
        }))
    }
  }, [mappedSchemaListNames, resolvedSchemaPayloads])

  useAsyncEffect(
    async () => {
      if (schemaPayloadsList?.length) {
        const list = await Promise.all(
          schemaPayloadsList.map<Promise<HashSchemaPayloadTuple>>(async (schemaPayload) => {
            const hash = await PayloadBuilder.dataHash(schemaPayload)
            return [hash, schemaPayload]
          }),
        )
        setHashedSchemaPayloadsList(list)
      }
    },
    [schemaPayloadsList],
  )

  return (
    <FlexCol minHeight="100dvh" busy={hashedSchemaPayloadsList.length === 0} gap={1}{...props}>
      <ErrorRender error={schemaListError}>
        { hashedSchemaPayloadsList.length > 0
          ? hashedSchemaPayloadsList.map(([hash, schemaPayload]) => {
            return (hash && schemaPayload.definition.$id)
              ? (
                  <FlexCol paper key={hash} alignItems="stretch">
                    <SchemaLoadedViewer alignItems="stretch" schema={schemaPayload} />
                  </FlexCol>
                )
              : null
          })

          : null}
      </ErrorRender>
    </FlexCol>
  )
}
