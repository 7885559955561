import type { Hash } from '@xylabs/hex'
import type { Payload } from '@xyo-network/protocol'
import { useWeakArchivistFromNode, useWeakArchivistGet } from '@xyo-network/react-modules'
import { PayloadDetails } from '@xyo-network/react-payload'
import { LoadResult } from '@xyo-network/react-shared'
import React from 'react'
import { useParams } from 'react-router-dom'

interface PayloadPointerRendererProps {
  payload?: Payload
}

export const PayloadPointerRenderer: React.FC<PayloadPointerRendererProps> = () => {
  const { hash } = useParams()
  const [archivist] = useWeakArchivistFromNode()
  const [payloads, error] = useWeakArchivistGet(archivist, hash ? [hash as Hash] : [])

  const payload = payloads?.pop()

  return (
    <LoadResult searchResult={payload} notFound={payload === null} apiError={error}>
      <PayloadDetails payload={payload ?? undefined} />
    </LoadResult>
  )
}
