import { Autocomplete, ClickAwayListener } from '@mui/material'
import { FlexGrowRow, FlexRow } from '@xylabs/react-flexbox'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AddressHistoryButton } from './AddressHistoryButton.tsx'
import { AddressStatsChips } from './AddressStatsChips.tsx'
import { useAddressAutoCompleteProps } from './hooks/index.ts'
import type { SharableAddressAutoCompleteProps } from './lib/index.ts'

export const AddressAutoComplete: React.FC<SharableAddressAutoCompleteProps> = ({
  addresses,
  hideSelectedAddressStats,
  showHistoryButton = true,
  wrapProps,
  ...props
}) => {
  const navigate = useNavigate()
  const { autoCompleteProps, clickAwayListenerProps } = useAddressAutoCompleteProps(addresses, props.value)

  const handleNavigation = () => (props.value ? navigate(`/address/${props.value?.address}`) : undefined)

  return (
    <FlexGrowRow justifyContent="start" alignItems="end" columnGap={2} flexWrap="wrap" {...wrapProps}>
      {!hideSelectedAddressStats && props.value
        ? (
            <FlexRow gap={1}>
              <AddressStatsChips address={autoCompleteProps.inputValue} />
            </FlexRow>
          )
        : null}
      <ClickAwayListener onClickAway={() => (clickAwayListenerProps.open ? clickAwayListenerProps.setOpen(false) : null)}>
        <Autocomplete style={{ display: 'flex', flexGrow: 1 }} {...autoCompleteProps} options={addresses ?? []} {...props} />
      </ClickAwayListener>
      {showHistoryButton
        ? <AddressHistoryButton value={props.value?.address} onClick={handleNavigation} />
        : null}
    </FlexGrowRow>
  )
}
