import { useAsyncEffect } from '@xylabs/react-async-effect'
import type { BoundWitnessStatsQuerySchema } from '@xyo-network/diviner-boundwitness-stats-model'
import type { PayloadStatsPayload, PayloadStatsQuerySchema } from '@xyo-network/diviner-payload-stats-model'
import type { Payload, WithMeta } from '@xyo-network/payload'
import { PayloadBuilder } from '@xyo-network/payload'
import { useDivinerFromNode } from '@xyo-network/react-modules'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'

type SupportedSchemas = BoundWitnessStatsQuerySchema | PayloadStatsQuerySchema
type SupportedDiviners = 'Bridge:PayloadStatsDiviner' | 'Bridge:BoundWitnessStatsDiviner'

export const useCount = (
  schema: SupportedSchemas,
  diviner: SupportedDiviners,
  address?: string,
): [number | null | undefined, Error | undefined, Dispatch<SetStateAction<number>>] => {
  const [count, setCount] = useState<number | null>()
  const [query, setQuery] = useState<Payload>()
  const [refresh, setRefresh] = useState(1)
  const [error, setError] = useState<Error>()

  const [divinerModule, divinerModuleError] = useDivinerFromNode(diviner)

  useAsyncEffect(
    async () => {
      if (address) {
        setQuery(await new PayloadBuilder({ schema }).fields({ address }).build())
      } else {
        setQuery(await new PayloadBuilder({ schema }).build())
      }
    },
    [address, refresh, schema],
  )

  useAsyncEffect(
    async (mounted) => {
      if (divinerModule) {
        try {
          const resultPayload = (await divinerModule.divine(query ? [query] : undefined)).pop() as WithMeta<PayloadStatsPayload>
          if (mounted()) {
            setCount(resultPayload?.count)
          }
        } catch (ex) {
          if (mounted()) {
            setError(ex as Error)
          }
        }
      }
    },
    [divinerModule, refresh, query],
  )

  return [count, error ?? divinerModuleError, setRefresh]
}
