import { useAppSettings } from '@xylabs/react-app-settings'
import { DefaultSeedPhrase } from '@xyo-network/react-wallet'
import type { PropsWithChildren } from 'react'
import React from 'react'

export const SeedPhrase: React.FC<PropsWithChildren> = ({ children }) => {
  const { changeSeedPhrase, seedPhrase } = useAppSettings()

  return (
    <DefaultSeedPhrase seedPhrase={seedPhrase} changeSeedPhrase={changeSeedPhrase}>
      {children}
    </DefaultSeedPhrase>
  )
}
