import type { Hash } from '@xylabs/hex'
import type { BasePageProps } from '@xylabs/react-common'
import { FlexCol } from '@xylabs/react-flexbox'
import { ApiEmbedPluginCard } from '@xyo-network/react-embed'
import { MapboxAccessTokenProvider } from '@xyo-network/react-map'
import { ListModeProvider, useListMode } from '@xyo-network/react-shared'
import { WebAppPage } from '@xyo-network/react-webapp'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useHuri } from '../../../../hooks/index.ts'
import { DynamicBreadcrumbs } from '../../../shared/index.ts'
import { useRender } from '../../contexts/index.ts'
import { DefaultPlugins } from '../../plugins/index.ts'

export const PayloadRenderPage: React.FC<BasePageProps> = (props) => {
  const [refresh, setRefresh] = useState(0)
  const hash = useParams().hash as Hash | undefined
  const { plugin } = useRender()
  console.log('plugin', plugin.name)
  const { listMode } = useListMode()
  const payload = useHuri(hash, refresh)
  const pluginsFromSchema = DefaultPlugins.filter(p => p.canRender(payload))
  return (
    <WebAppPage breadcrumbs={<DynamicBreadcrumbs />} {...props}>
      <MapboxAccessTokenProvider defaultAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}>
        <ListModeProvider>
          <FlexCol marginY={2} alignItems="stretch" justifyContent="stretch" height="100%">
            <ApiEmbedPluginCard
              style={{ height: plugin.fillContainer ? '100%' : 'inherit' }}
              huriPayload={payload ?? ''}
              plugins={[...pluginsFromSchema, plugin]}
              embedPluginConfig={{ listMode }}
              busyMinimum={500}
              busy={payload === undefined}
              onRefresh={() => setRefresh(refresh + 1)}
            />
          </FlexCol>
        </ListModeProvider>
      </MapboxAccessTokenProvider>
    </WebAppPage>
  )
}
