import { WebAppNotFoundPage } from '@xyo-network/react-webapp'
import React from 'react'
import type { RouteProps } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import { ArchivistDetailPage, ArchivistListPage } from './pages/index.ts'

export const ArchivistRoutes: React.FC<RouteProps> = (props) => {
  return (
    <Routes {...props}>
      <Route path="/:archivist/*" element={<ArchivistDetailPage />} />
      <Route path="/" element={<ArchivistListPage />} />
      <Route path="*" element={<WebAppNotFoundPage />} />
    </Routes>
  )
}
