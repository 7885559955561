import { PoweredByXyo } from '@xyo-network/react-powered-by-xyo'
import type { PropsWithChildren } from 'react'
import React from 'react'

import { AppOsThemeProvider } from './AppOsThemeProvider.tsx'
import { AppOsChrome } from './Chrome.tsx'

export const AppShell: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppOsThemeProvider>
      <AppOsChrome>
        {children}
        <PoweredByXyo debugDialog />
      </AppOsChrome>
    </AppOsThemeProvider>
  )
}
