/* eslint-disable import-x/no-internal-modules */

import type { MenuListItemProps } from '@xylabs/sdk-react'
import { useMenuItemsShared } from '@xylabs/sdk-react'
import React, { useMemo } from 'react'
import { BsFileEarmarkCode } from 'react-icons/bs'
import { TbActivity } from 'react-icons/tb'
import {
  VscGlobe, VscHome, VscSettingsGear, VscSymbolMethod, VscSymbolNamespace,
} from 'react-icons/vsc'

export const useMenuItems = () => {
  const { defaultSiteMenuListItemProps } = useMenuItemsShared()

  const MenuItemsChainData: MenuListItemProps[] = useMemo(
    () => [
      {
        icon: <VscHome fontSize="body1" />,
        primary: 'Home',
        to: '/',
        tooltip: 'Explore the currently selected Node',
        ...defaultSiteMenuListItemProps,
      },
      {
        icon: <VscGlobe fontSize="body1" />,
        primary: 'Explore',
        to: '/address',
        tooltip: 'Browse addresses on this node.',
        ...defaultSiteMenuListItemProps,
      },
      {
        icon: <VscSymbolMethod fontSize="body1" />,
        primary: 'Blocks',
        to: '/block',
        tooltip: 'The most recent blocks seen in the in temp archive on your current network.',
        ...defaultSiteMenuListItemProps,
      },
      {
        icon: <VscSymbolNamespace fontSize="body1" />,
        primary: 'Payloads',
        to: '/payload',
        tooltip: 'The most recent blocks seen in the in temp archive on your current network.',
        ...defaultSiteMenuListItemProps,
      },
      // {
      //   icon: <VscGraph fontSize="body1" />,
      //   primary: 'Queries',
      //   to: '/queries',
      //   tooltip: 'The most recent blocks seen in the in temp archive on your current network.',
      //   ...defaultSiteMenuListItemProps,
      // },
      // {
      //   icon: <VscServer fontSize="body1" />,
      //   primary: 'Archives',
      //   subNavListItems: [
      //     { icon: <VscDashboard fontSize="body1" />, primary: 'Dashboard', tooltip: 'View data for all archives on this network.' },
      //     { icon: <VscAdd fontSize="body1" />, primary: 'Create Archive', tooltip: 'Create a new archive on this network.' },
      //     { icon: <VscTable fontSize="body1" />, primary: 'Archive Table', tooltip: 'View all archives on this network as a table.' },
      //   ],
      //   to: '/archive',
      //   tooltip: 'The most recent blocks seen in the in temp archive on your current network.',
      //   ...defaultSiteMenuListItemProps,
      // },
    ],
    [defaultSiteMenuListItemProps],
  )

  const MenuItemsMisc: MenuListItemProps[] = useMemo(
    () => [
      {
        icon: <TbActivity fontSize="body1" />,
        primary: 'Activity',
        to: '/activity',
        tooltip: 'View global activity on a world map.',
        ...defaultSiteMenuListItemProps,
      },
      {
        icon: <BsFileEarmarkCode fontSize="body1" />,
        primary: 'Schema',
        to: '/schema',
        tooltip: `The most recent blocks seen in the in ${'temp'} archive on your current network.`,
        ...defaultSiteMenuListItemProps,
      },
      // {
      //   icon: <VscAccount fontSize="body1" />,
      //   primary: 'Account',
      //   to: '/accounts',
      //   tooltip: 'The most recent blocks seen in the in temp archive on your current network.',
      //   ...defaultSiteMenuListItemProps,
      // },
      {
        icon: <VscSettingsGear fontSize="body1" />,
        primary: 'Settings',
        to: '/settings',
        tooltip: 'The most recent blocks seen in the in temp archive on your current network.',
        ...defaultSiteMenuListItemProps,
      },
    ],
    [defaultSiteMenuListItemProps],
  )

  return { MenuItemsChainData, MenuItemsMisc }
}
