import { Divider } from '@mui/material'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { useEvent } from '@xyo-network/react-event'
import { SchemaProperty } from '@xyo-network/react-schema'
import type { SchemaPayload } from '@xyo-network/schema-payload-plugin'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export interface SchemaLoadedViewerProps extends FlexBoxProps {
  schema: SchemaPayload
}

export const SchemaLoadedViewer: React.FC<SchemaLoadedViewerProps> = ({ schema, ...props }) => {
  const navigate = useNavigate()
  const [ref] = useEvent<HTMLDivElement>((noun, _verb, data) => {
    console.log('Event', noun, data)
    if (noun === 'schema' && data) {
      const parsedEvent = JSON.parse(data)
      const openInNewWindow = parsedEvent.openNewWindow
      const schema = parsedEvent.schema

      if (openInNewWindow) {
        const {
          pathname, search, origin,
        } = globalThis.location
        window.open(`${origin}${pathname}/${schema}${search}`, '_blank')
      } else {
        navigate(schema)
      }
    }
  })

  return (
    <FlexCol alignItems="stretch" {...props}>
      <SchemaProperty ref={ref} value={schema.definition.$id} />
      <FlexRow marginY={2}><Divider /></FlexRow>
    </FlexCol>
  )
}
