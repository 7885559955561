import type { BreadcrumbsProps } from '@mui/material'
import { Breadcrumbs } from '@mui/material'
import { FlexRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import { ShareButton } from '@xyo-network/react-share'
import React from 'react'
import type { To } from 'react-router-dom'

import type { BreadcrumbData } from '../../Breadcrumb.ts'
import { BreadcrumbProvider, useBreadcrumbs } from '../../contexts/index.ts'

export interface DynamicBreadcrumbs extends BreadcrumbsProps {
  name?: string
  to?: To
}

const Inner: React.FC<BreadcrumbsProps & { resolvedBreadcrumbs: BreadcrumbData[] }> = ({ resolvedBreadcrumbs, ...props }) => {
  return (
    <Breadcrumbs {...props}>
      {resolvedBreadcrumbs.map((breadcrumb, index) => (
        <LinkEx key={index} color="secondary" to={breadcrumb.to}>
          {breadcrumb.name}
        </LinkEx>
      ))}
    </Breadcrumbs>
  )
}

export const DynamicBreadcrumbs: React.FC<DynamicBreadcrumbs> = ({
  name, to, children, ...props
}) => {
  const { breadcrumbs = [], root = '/' } = useBreadcrumbs()

  const resolvedTo = to ? `${root}${to}/` : root

  const resolvedBreadcrumbs = [...breadcrumbs, ...(name ? [{ name, to: resolvedTo }] : [])]

  return (
    <FlexRow gap={1} flexWrap="wrap">
      {name
        ? (
            <BreadcrumbProvider name={name} to={to}>
              <Inner resolvedBreadcrumbs={resolvedBreadcrumbs} {...props} />
              {children}
            </BreadcrumbProvider>
          )
        : <Inner resolvedBreadcrumbs={resolvedBreadcrumbs} {...props} />}
      <ShareButton />
    </FlexRow>
  )
}
