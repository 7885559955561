import { ErrorReporterProvider } from '@xylabs/react-common'
import { Fbq, Gtag } from '@xylabs/react-pixel'
import { ConsoleLogger, LogLevel } from '@xylabs/sdk-js'
import { AbstractModule } from '@xyo-network/module'
import { ThrownErrorBoundary } from '@xyo-network/react-error'
import { NetworkRouteProvider, resolveNetworkFromHostName } from '@xyo-network/react-network'
import { PixelProvider } from '@xyo-network/react-pixel'
import { SchemaCache } from '@xyo-network/schema-cache'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Rollbar from 'rollbar'

import { GlobalNode } from './GlobalNode.tsx'
import { getApiDomain } from './lib/index.ts'
import {
  AppShell, ExplorerFavorites, RootRoutes, SeedPhrase, SettingsProviders, UIProviders, Wallet,
} from './modules/index.ts'

const getRollbarEnvironment = () => {
  switch (document.location.hostname) {
    case 'explore.xyo.network': {
      return 'production'
    }
    case 'beta.explore.xyo.network': {
      return 'beta'
    }
    default: {
      return 'development'
    }
  }
}

AbstractModule.defaultLogger
  = import.meta.env.VITE_DEBUG_MODULES?.toLowerCase() === 'true' ? new ConsoleLogger(LogLevel.debug) : AbstractModule.defaultLogger

const rollbar = new Rollbar({
  accessToken: 'de72a8fbda514e84a08e06e0d0fbbe5b',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: getRollbarEnvironment(),
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
})

Fbq.init(import.meta.env.VITE_FACEBOOK_PIXEL_ID)
Gtag.init('G-795QBPW744', 'AW-989965544', ['explore.xyo.network'])

const defaultProxy = 'https://beta.api.archivist.xyo.network/domain'
SchemaCache.instance.proxy = getApiDomain().startsWith('http://localhost') ? defaultProxy : undefined

export const App: React.FC = () => {
  return (
    <ErrorReporterProvider rollbar={rollbar}>
      <ThrownErrorBoundary boundaryName="App">
        <PixelProvider id="explore.xyo.network">
          <SettingsProviders>
            <SeedPhrase>
              <Wallet>
                <BrowserRouter>
                  <NetworkRouteProvider defaultNetworkName={resolveNetworkFromHostName('Kerplunk', 'explore')}>
                    <GlobalNode>
                      <ExplorerFavorites>
                        <UIProviders>
                          <AppShell>
                            <ThrownErrorBoundary scope="App">
                              <RootRoutes />
                            </ThrownErrorBoundary>
                          </AppShell>
                        </UIProviders>
                      </ExplorerFavorites>
                    </GlobalNode>
                  </NetworkRouteProvider>
                </BrowserRouter>
              </Wallet>
            </SeedPhrase>
          </SettingsProviders>
        </PixelProvider>
      </ThrownErrorBoundary>
    </ErrorReporterProvider>
  )
}
