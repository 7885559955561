import { assertEx } from '@xylabs/assert'
import { forget } from '@xylabs/forget'
import React from 'react'
import {
  createRoot,
// eslint-disable-next-line import-x/no-internal-modules
} from 'react-dom/client'

import { App } from './App.tsx'
import * as serviceWorker from './serviceWorker.ts'

forget(serviceWorker.unregister())

const root = createRoot(assertEx(document.querySelector('#root')))

root.render(<App />)
