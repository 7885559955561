import { FlexGrowCol } from '@xylabs/react-flexbox'
import { useColorSchemeEx } from '@xylabs/react-invertible-theme'
import { XyoFooter } from '@xyo-network/react-footer'
import { XyoWebAppChrome } from '@xyo-network/react-webapp'
import type { PropsWithChildren } from 'react'
import React from 'react'

import { PortalAppBar } from '../components/index.ts'
import {
  dark1, dark2, dark3, dark4, light1, light2, light3, light4,
} from './img/index.ts'

export const AppOsChrome: React.FC<PropsWithChildren> = ({ children }) => {
  const { darkMode } = useColorSchemeEx()
  const lightBgOptions = [light1, light2, light3, light4]
  const darkBgOptions = [dark1, dark2, dark3, dark4]
  const randomLight = lightBgOptions[Math.floor(Math.random() * lightBgOptions.length)]
  const randomDark = darkBgOptions[Math.floor(Math.random() * darkBgOptions.length)]
  return (
    <XyoWebAppChrome
      sx={{
        backgroundImage: `url(${darkMode ? randomDark : randomLight})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: { md: '500px', xs: '200px' },
        overflow: 'hidden',
      }}
      appName="XYO Explore"
      footer={(
        <FlexGrowCol alignItems="stretch">
          <XyoFooter dynamicHeight />
        </FlexGrowCol>
      )}
      appbar={<PortalAppBar />}
    >
      {children}
    </XyoWebAppChrome>
  )
}
