import { useAsyncEffect } from '@xylabs/react-async-effect'
import type { ArchivistInstance } from '@xyo-network/archivist'
import type { WithMeta } from '@xyo-network/payload'

import { useFavoriteAddresses } from '../contexts/index.ts'
import type { FavoriteAddressPayload } from '../lib/index.ts'
import { FavoriteAddressSchema } from '../lib/index.ts'

export const useRestoreFavoriteAddresses = (favoritesArchivist?: ArchivistInstance) => {
  const { addFavoriteAddresses } = useFavoriteAddresses(true)

  useAsyncEffect(
    async () => {
      if (favoritesArchivist) {
        const payloads = (await favoritesArchivist.all?.()) as WithMeta<FavoriteAddressPayload>[] | undefined
        const filtered = payloads?.filter(payload => payload.schema === FavoriteAddressSchema)
        if (filtered) {
          addFavoriteAddresses?.(filtered)
        }
      }
    },
    [addFavoriteAddresses, favoritesArchivist],
  )
}
