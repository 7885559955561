import type { DivinerInstance } from '@xyo-network/diviner'
import { BoundWitnessDivinerConfigSchema } from '@xyo-network/diviner-boundwitness-model'
import { useWeakArchivistFromNode } from '@xyo-network/react-modules'
import { useMemo } from 'react'

import { useSelectedArchivist } from '../../../hooks/index.ts'
import { useDivinersFromNodeByConfigSchema } from '../../shared/index.ts'

export const useSelectedBoundWitnessDiviner = (): [DivinerInstance | undefined, Error | undefined] => {
  const [selectedArchivistAddress] = useSelectedArchivist()
  const [selectedArchivist, selectedArchivistError] = useWeakArchivistFromNode(selectedArchivistAddress)
  const [addressHistoryDiviners, addressHistoryDivinersError] = useDivinersFromNodeByConfigSchema(BoundWitnessDivinerConfigSchema)

  const selectedAddressHistoryDiviner = useMemo(() => {
    const selectedArchivistInstance = selectedArchivist?.deref()
    if (selectedArchivistInstance && addressHistoryDiviners) {
      return addressHistoryDiviners.find((addressHistoryDiviner) => {
        return (
          addressHistoryDiviner.config.archivist === selectedArchivistInstance.address
          || addressHistoryDiviner.config.archivist === selectedArchivistInstance.config.name
        )
      })
    }
  }, [selectedArchivist, addressHistoryDiviners])
  return [selectedAddressHistoryDiviner, selectedArchivistError ?? addressHistoryDivinersError]
}
