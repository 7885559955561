import type { PropsWithChildren } from 'react'
import React, {
  useCallback, useMemo, useState,
} from 'react'

import type { FavoriteAddressPayload } from '../lib/index.ts'
import { FavoriteAddressesContext } from './FavoriteAddressesContext.ts'
import type { AddressAliases, FavoriteAddressesState } from './FavoriteAddressState.ts'

export type FavoriteAddressesProviderProps = PropsWithChildren

export const FavoriteAddressesProvider: React.FC<FavoriteAddressesProviderProps> = ({ children }) => {
  const [addressNames, setAddressAliases] = useState<AddressAliases>({})

  const addFavoriteAddresses: FavoriteAddressesState['addFavoriteAddresses'] = useCallback((favoriteAddresses: FavoriteAddressPayload[]) => {
    setAddressAliases((previous) => {
      if (favoriteAddresses)
        for (const favoriteAddress of favoriteAddresses) {
          previous[favoriteAddress.address] = favoriteAddress.name
        }
      return { ...previous }
    })
  }, [])

  const removeFavoriteAddresses: FavoriteAddressesState['removeFavoriteAddresses'] = useCallback((favoriteAddresses: FavoriteAddressPayload[]) => {
    setAddressAliases((previous) => {
      let changed = false
      favoriteAddresses?.map((favoriteAddress) => {
        if (favoriteAddress.address in previous) {
          changed = true
          delete previous[favoriteAddress.address]
          return { ...previous }
        } else {
          return previous
        }
      })
      return changed ? { ...previous } : previous
    })
  }, [])

  const value = useMemo(() => ({
    addFavoriteAddresses, addressNames, provided: true, removeFavoriteAddresses,
  }), [addFavoriteAddresses, addressNames, removeFavoriteAddresses])

  return (
    <FavoriteAddressesContext.Provider
      value={value}
    >
      {children}
    </FavoriteAddressesContext.Provider>
  )
}
